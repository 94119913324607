import React from 'react';
import { Show, SimpleShowLayout, TextField, ShowProps } from 'react-admin';

const HouseShow: React.FC<ShowProps> = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="house_name" label="House Name" />
            <TextField source="address" label="Address" />
            <TextField source="ward" label="Ward" />
            <TextField source="district" label="District" />
            <TextField source="city" label="City" />
        </SimpleShowLayout>
    </Show>
);

export default HouseShow;
