import React from 'react';
import { Create, useRedirect, useNotify, useDataProvider } from 'react-admin';
import { SimpleForm } from 'react-admin';
import ExpenseForm from './ExpenseForm';

const ExpenseCreate: React.FC = (props) => {
    const redirect = useRedirect();
    const notify = useNotify();
    const dataProvider = useDataProvider();

    const handleSave = async (data: any) => {
        try {
            const { expense_items = [], ...expenseData } = data;

            // Ensure amount is calculated if missing
            const totalAmount = expense_items.reduce((sum, item) => sum + (item.amount || 0), 0);
            expenseData.amount = totalAmount;

            // Create expense
            const expenseResponse = await dataProvider.create('expenses', { data: expenseData });

            // Create expense items
            if (expense_items.length > 0) {
                const itemsWithExpenseId = expense_items.map((item) => ({
                    ...item,
                    expense_id: expenseResponse.data.id,
                }));
                await Promise.all(
                    itemsWithExpenseId.map((item) => dataProvider.create('expense_items', { data: item }))
                );
            }

            notify('Expense created successfully', { type: 'success' });
            redirect('list', 'expenses');
        } catch (error) {
            console.error('Error creating expense:', error);
            notify(`Error: ${error.message}`, { type: 'error' });
        }
    };
    const defaultValues = {
        expense_date: new Date().toISOString().split('T')[0], // Today's date in YYYY-MM-DD format
        description: '', // Example default value for description
        expense_items: [], // Example default value for items
    };

    return (
        <Create {...props}>
            <SimpleForm onSubmit={handleSave} defaultValues={defaultValues}>
                <ExpenseForm />
            </SimpleForm>
        </Create >
    );
};

export default ExpenseCreate;
