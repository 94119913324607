
import InvoiceList from './InvoiceList';
import InvoiceShow from './InvoiceShow';
// import UtilityUsageCreate from './UtilityUsageCreate';

const InvoicesPage = {
    name: 'invoices',
    list: InvoiceList,
    // create: UtilityUsageCreate,
    edit: InvoiceShow,
    // show: InvoiceShow,
};

export default InvoicesPage;
