import React, { useRef, useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Scanner as ScannerComp, IScannerProps, outline, boundingBox, centerText, useDevices } from '@yudiel/react-qr-scanner';
import CameraIcon from '@mui/icons-material/Camera';
const styles = {
    container: {
        width: 300,
        margin: 'auto'
    },
    controls: {
        marginBottom: 8
    }
};
/**
 * QRReaderButton Component
 * Encapsulates the QR Scanner button and the dialog to scan a QR code.
 * It calls `onScanSuccess` when a QR code is successfully scanned.
 */
const QRReaderButton: React.FC<{ onScanSuccess: (data: string) => void, defaultZoom: number }> = ({ onScanSuccess, defaultZoom }) => {
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [deviceId, setDeviceId] = useState<string | undefined>(undefined);
    const [tracker, setTracker] = useState<string | undefined>('centerText');

    const [pause, setPause] = useState(false);

    const devices = useDevices();

    function getTracker() {
        switch (tracker) {
            case 'outline':
                return outline;
            case 'boundingBox':
                return boundingBox;
            case 'centerText':
                return centerText;
            default:
                return undefined;
        }
    }

    const handleClose = () => {
        setDialogOpen(false);
        setPause(false);
    };

    return (
        <>
            {/* QR Scanner Button */}
            <Button variant="contained" onClick={() => setDialogOpen(true)} startIcon={<CameraIcon />}>
                Scan QR
            </Button>

            {/* QR Scanner Dialog */}
            <Dialog open={isDialogOpen} onClose={handleClose} fullWidth>
                <DialogTitle>Scan QR Code</DialogTitle>
                <DialogContent>
                    <div style={styles.container}>
                        <button style={{ marginBottom: 5 }} onClick={() => setPause((val) => !val)}>
                            {pause ? 'Pause Off' : 'Pause On'}
                        </button>
                        <div style={styles.controls}>
                            <select onChange={(e) => setDeviceId(e.target.value)}>
                                <option value={undefined}>Select a device</option>
                                {devices.map((device, index) => (
                                    <option key={index} value={device.deviceId}>
                                        {device.label}
                                    </option>
                                ))}
                            </select>
                            <select style={{ marginLeft: 5 }} onChange={(e) => setTracker(e.target.value)}>
                                <option value="centerText">Center Text</option>
                                <option value="outline">Outline</option>
                                <option value="boundingBox">Bounding Box</option>
                                <option value={undefined}>No Tracker</option>
                            </select>
                        </div>
                        <ScannerComp
                            // {...args}
                            formats={[
                                'qr_code',
                                'micro_qr_code',
                                'rm_qr_code',
                            ]}
                            constraints={{
                                deviceId: deviceId,
                                facingMode: { exact: "environment" },
                                // width: { min: 1024, ideal: 4096, max: 4096 },
                                // height: { min: 540, ideal: 2160, max: 2160 },
                                // frameRate: { ideal: 60, max: 60 },
                                advanced: [{ zoom: defaultZoom || 1 }]
                            }}
                            onScan={(detectedCodes) => {
                                let first = detectedCodes[0];
                                console.log(JSON.stringify(first));
                                onScanSuccess(first.rawValue.replace('http://mhvn.vn/?c=', ''), first);
                                handleClose();
                            }}
                            onError={(error) => {
                                console.log(`onError: ${error}'`);
                            }}
                            components={{
                                audio: false,
                                onOff: true,
                                torch: true,
                                zoom: true,
                                finder: true,
                                tracker: getTracker()
                            }}
                            allowMultiple={false}
                            scanDelay={2000}
                            paused={pause}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default QRReaderButton;
