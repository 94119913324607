
import UtilityUsageList from './UtilityUsageList';
import UtilityUsageCreate from './UtilityUsageCreate';
import MultipleUtilityUsageCreate from './MultipleUtilityUsageCreate';
import UtilityUsageShow from './UtilityUsageShow';
import UtilityUsageEdit from './UtilityUsageEdit';

const UtilityUsagesPage = {
    name: 'utility_usages',
    list: UtilityUsageList,
    create: UtilityUsageCreate,
    edit: UtilityUsageEdit,
    show: UtilityUsageShow,
};

export default UtilityUsagesPage;
