import React from 'react';
import { Edit, SimpleForm, ReferenceField, TextField, DateInput, NumberInput, EditProps, Labeled, required } from 'react-admin';
import ImageFileInput from '../../components/ImageFileInput';  // Import the custom ImageFileInput component

/**
 * Component to edit utility usage records.
 *
 * This component provides fields to edit utility usage details such as month/year, electric meter reading,
 * and an image URL. The contract ID field is displayed as a read-only reference to prevent modification.
 *
 * @param props - The props for the Edit view, inherited from react-admin's EditProps.
 */
const UtilityUsageEdit: React.FC<EditProps> = (props) => (
    <Edit {...props}>
        <SimpleForm>
            {/* Read-only Contract ID with nested ReferenceField to display room name */}
            <ReferenceField label="Room" source="contract_id" reference="contracts" link={false}>
                <ReferenceField source="room_id" reference="rooms" link={false}>
                    <Labeled label="Room">
                        <TextField source="room_name" />
                    </Labeled>
                </ReferenceField>
            </ReferenceField>

            {/* Month/Year: Editable date input */}
            <DateInput source="month_year" label="Month/Year" />

            {/* Electric Meter Reading: Editable number input */}
            <NumberInput source="electric_meter_reading" label="Electric Meter Reading" />

            {/* Image URL: Editable image input with preview */}
            <ImageFileInput source="image_url" label="Image URL" />
        </SimpleForm>
    </Edit>
);

export default UtilityUsageEdit;
