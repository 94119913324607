import React from 'react';
import { Create, SimpleForm, TextInput, CreateProps } from 'react-admin';

const HouseCreate: React.FC<CreateProps> = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="house_name" label="House Name" />
            <TextInput source="address" label="Address" />
            <TextInput source="ward" label="Ward" />
            <TextInput source="district" label="District" />
            <TextInput source="city" label="City" />
        </SimpleForm>
    </Create>
);

export default HouseCreate;
