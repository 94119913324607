import React from 'react';
import { useRecordContext, useRedirect, Button } from 'react-admin';
import { supabaseClient as supabase } from '../../../core/supabase'; // Adjust path as needed
import AddIcon from '@mui/icons-material/Add';

const CreateCommissionExpenseButton = () => {
    const record = useRecordContext();
    const redirect = useRedirect();

    if (!record || !!record.commission_expense_id) {
        return null;
    }

    const handleClick = async () => {
        try {
            const { error } = await supabase.rpc('create_commission_expense', {
                p_contract_id: record.id,
            });
            if (error) {
                throw error;
            }
            redirect(`/expenses?displayedFilters={}&filter=${encodeURIComponent(`{"contract_id":${record.id}}`)}`);
        } catch (error) {
            console.error('Error creating commission expense:', error);
            // Optionally, display a notification to the user
        }
    };

    return (
        <Button label="Pay Commission" onClick={handleClick} startIcon={<AddIcon />} />
    );
};

export default CreateCommissionExpenseButton;
