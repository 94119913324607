import React, { useState } from 'react';
import { useRecordContext, useNotify, useRedirect } from 'react-admin';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Typography } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { supabaseClient as supabase } from '../../../core/supabase';

const TerminateContractButton: React.FC = () => {
    const record = useRecordContext(); // Get the current contract record
    const notify = useNotify();
    const redirect = useRedirect();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [confirmText, setConfirmText] = useState('');
    const [error, setError] = useState<string | null>(null);

    const handleDialogClose = () => {
        setDialogOpen(false);
        setConfirmText('');
        setError(null);
    };

    const handleTerminate = async () => {
        if (!record?.id) {
            notify('No contract record found.', { type: 'error' });
            return;
        }

        if (confirmText.trim().toLowerCase() !== 'terminate') {
            setError('You must type "terminate" to confirm.');
            return;
        }

        try {
            // Call Supabase RPC to terminate contract
            const { error: rpcError } = await supabase.rpc('terminate_contract', { p_contract_id: record.id });

            if (rpcError) {
                throw new Error(rpcError.message);
            }

            // Redirect to expenses filtered by the contract ID
            redirect(
                `/expenses?displayedFilters={}&filter=${encodeURIComponent(`{"contract_id":${record.id}}`)}`
            );
            notify('Contract terminated successfully.', { type: 'success' });
            handleDialogClose();
        } catch (err: any) {
            console.error('Error terminating contract:', err);
            notify(`Error terminating contract: ${err.message}`, { type: 'error' });
        }
    };

    return (
        <>
            {/* Terminate Button */}
            <Button
                variant="contained"
                color="error"
                startIcon={<CancelOutlinedIcon />}
                onClick={() => setDialogOpen(true)}
            >
                Terminate
            </Button>

            {/* Confirmation Dialog */}
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Confirm Contract Termination</DialogTitle>
                <DialogContent>
                    <Typography gutterBottom>
                        To confirm termination, please type <strong>terminate</strong> below:
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="terminate"
                        value={confirmText}
                        onChange={(e) => setConfirmText(e.target.value)}
                        error={!!error}
                        helperText={error}
                        margin="dense"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleTerminate} color="error" disabled={!confirmText}>
                        Terminate
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TerminateContractButton;
