import React from 'react';
import { Create } from 'react-admin';
import ContractForm from './ContractForm';

const ContractCreate: React.FC = () => (
    <Create>
        <ContractForm />
    </Create>
);

export default ContractCreate;
