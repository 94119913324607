import React, { useEffect, useState } from 'react';
import { ArrayInput, SimpleFormIterator, TextInput, useNotify } from 'react-admin';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { Grid } from '@mui/material';
import QRReaderButton from '../../components/QRReaderButton';
import { supabaseClient as supabase } from '../../core/supabase';

type Asset = {
    id: number;
    serial_number: string;
    asset_type: string;
    status: string;
    room_id: number | null;
};

const AssetListIterator: React.FC<{ roomId: string }> = ({ roomId }) => {
    const notify = useNotify();
    const { control, getValues } = useFormContext();
    const { fields, append } = useFieldArray({
        control,
        name: 'assets',
    });
    const [refreshKey, setRefreshKey] = useState(0);

    // Fetch initial assets assigned to the room
    useEffect(() => {
        const fetchAssets = async () => {
            try {
                const { data, error } = await supabase
                    .from('assets')
                    .select('id, serial_number, asset_type, status, room_id,price')
                    .eq('room_id', roomId);

                if (error) throw error;
                if (data) {
                    data.forEach(asset => append(asset));
                    setRefreshKey((prevKey) => prevKey + 1); // Trigger re-render
                }
            } catch (error) {
                notify('Error loading assets', { type: 'error' });
            }
        };
        fetchAssets();
    }, [roomId, notify, append]);

    // Simulate a scan test after mounting
    // useEffect(() => {
    //     const timeoutId = setTimeout(() => {
    //         readFromIdQR('C763G8A55LPP5X');
    //     }, 2000); // 2-second delay for testing

    //     return () => clearTimeout(timeoutId);
    // }, []);

    // Handle QR scan success
    const readFromIdQR = async (result: string) => {
        try {
            const { data: asset, error } = await supabase
                .from('assets')
                .select('id, serial_number, asset_type, status, room_id,price')
                .eq('serial_number', result)
                .single();

            if (error || !asset) {
                notify('Asset not found', { type: 'error' });
                return;
            }

            if (asset.status === 'DECOMMISSIONED') {
                notify('Asset is decommissioned and cannot be added', { type: 'warning' });
                return;
            }

            const currentAssets = getValues('assets') || [];
            if (currentAssets.some((item: Asset) => item.id === asset.id)) {
                notify('Duplicate asset - already in list', { type: 'warning' });
                return;
            }


            append(asset); // Append asset to form array
            setRefreshKey((prevKey) => prevKey + 1); // Update key to force re-render
        } catch (error) {
            notify('Error looking up asset', { type: 'error' });
        }
    };

    return (
        <>
            <ArrayInput source="assets" resource="assets" key={refreshKey}>
                <SimpleFormIterator disableClear={true} disableAdd={false} disableReordering={true} disableRemove={false} addButton={<QRReaderButton onScanSuccess={(result) => readFromIdQR(result)} />}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <TextInput source="id" type="hidden" label={false} style={{ display: 'none' }} />
                            <TextInput source="serial_number" label="Serial Number" fullWidth />
                        </Grid>
                        <Grid item xs={4}>
                            <TextInput source="asset_type" label="Asset Type" fullWidth />
                        </Grid>
                        <Grid item xs={4}>
                            <TextInput source="status" label="Status" fullWidth />
                        </Grid>
                    </Grid>
                </SimpleFormIterator>
            </ArrayInput>
            {/* <div style={{ padding: '1rem' }}>
                <QRReaderButton onScanSuccess={(result) => readFromIdQR(result)} />
            </div> */}
        </>
    );
};

export default AssetListIterator;
