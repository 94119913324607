import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { TextField, DateField, Labeled, useRecordContext } from 'react-admin';
import ImageDisplay from '../../../components/ImageDisplay';

/**
 * Component to display customer information in a two-row layout for each customer.
 */
const CustomerDisplaySection: React.FC = () => {
    const record = useRecordContext(); // Access the record context for the current contract

    if (!record || !record.contract_customers) {
        return <Typography variant="body2">No customer information available.</Typography>;
    }

    return (
        <Box sx={{ width: '100%' }}>
            {record.contract_customers.map((customer: any, index: number) => (
                <Box key={index} sx={{ marginBottom: 3, padding: 2, border: '1px solid #ddd', borderRadius: 1 }}>
                    {/* First Row - Customer Details */}
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={3}>
                            <Labeled label="Customer Name">
                                <TextField source="customer_name" record={customer} />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Labeled label="Date of Birth">
                                <DateField source="customer_dob" record={customer} />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Labeled label="Identity Number">
                                <TextField source="customer_identity_number" record={customer} />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Labeled label="ID Issue Date">
                                <DateField source="customer_id_issue_date" record={customer} />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12}>
                            <Labeled label="Customer Address">
                                <TextField source="customer_address" record={customer} />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Labeled label="Phone Number">
                                <TextField source="phone_number" record={customer} />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Labeled label="Email">
                                <TextField source="email" record={customer} />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Labeled label="Motorbike License Plate">
                                <TextField source="bike_license_plate" record={customer} />
                            </Labeled>
                        </Grid>
                    </Grid>

                    {/* Second Row - Images */}
                    <Grid container spacing={2} sx={{ marginTop: 2 }}>
                        <Grid item xs={12} sm={4}>
                            <Labeled label="Front ID">
                                <ImageDisplay
                                    source="id_card_front_url"
                                    record={customer}
                                    placeholder="/path/to/id-card-front-placeholder.svg"
                                />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Labeled label="Back ID">
                                <ImageDisplay
                                    source="id_card_back_url"
                                    record={customer}
                                    placeholder="/path/to/id-card-back-placeholder.svg"
                                />
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Labeled label="Motorbike Image">
                                <ImageDisplay
                                    source="bike_image_url"
                                    record={customer}
                                    placeholder="/path/to/bike-placeholder.svg"
                                />
                            </Labeled>
                        </Grid>
                    </Grid>
                </Box>
            ))}
        </Box>
    );
};

export default CustomerDisplaySection;
