import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    FunctionField,
    useDataProvider,
    useRecordContext,
    ReferenceInput,
    SelectInput,
    AutocompleteInput
} from 'react-admin';

const PrimaryCustomerField: React.FC = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const [primaryCustomer, setPrimaryCustomer] = React.useState<string | null>(null);

    React.useEffect(() => {
        if (record && record.id) {
            // Fetch the primary customer where contract_id is the current contract and is_primary = true
            dataProvider
                .getList('customers', {
                    filter: { contract_id: record.id, is_primary: true },
                    pagination: { page: 1, perPage: 1 },
                })
                .then(({ data }) => {
                    if (data.length > 0) {
                        setPrimaryCustomer(data[0].customer_name);
                    } else {
                        setPrimaryCustomer('N/A');
                    }
                })
                .catch(() => setPrimaryCustomer('N/A'));
        }
    }, [record, dataProvider]);

    return primaryCustomer || 'N/A';
};

const postFilters = [
    <ReferenceInput source="room_id" reference="rooms" label="Room" alwaysOn perPage={1000} >
        <AutocompleteInput optionText="room_name" optionValue="id" filterToQuery={searchText => ({ room_name: searchText })} />
    </ReferenceInput >
];

const ContractList: React.FC = (props) => (
    <List filters={postFilters} {...props}>
        <Datagrid >
            {/* Display contract start and end dates */}
            <DateField source="start_date" label="Start Date" />
            <TextField source="duration" label="Duration" />

            {/* Display room name */}
            <ReferenceField source="room_id" reference="rooms" label="Room">
                <TextField source="room_name" />
            </ReferenceField>

            {/* Display primary customer name */}
            <FunctionField label="Primary Customer" render={() => <PrimaryCustomerField />} />

            {/* Display contract status */}
            <TextField source="status" label="Status" />
        </Datagrid>
    </List>
);

export default ContractList;
