
import React from 'react';
import { Create, SimpleForm, TextInput, NumberInput, CreateProps, SelectInput } from 'react-admin';

const ServiceFeeCreate: React.FC<CreateProps> = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="service_name" label="Service Name" />
            <SelectInput
                source="fee_type"
                label="Fee Type"
                choices={[
                    { id: 'RENT', name: 'Rent' },
                    { id: 'SERVICE', name: 'Service' },
                ]}
                defaultValue="SERVICE"
            />
            <NumberInput source="default_fee" label="Default Fee" />
            <TextInput source="description" label="Description" />
        </SimpleForm>
    </Create>
);

export default ServiceFeeCreate;
