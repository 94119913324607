import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useRecordContext } from 'react-admin';
import { supabaseClient as supabase } from '../core/supabase';
import { useTheme, alpha } from '@mui/material/styles';

interface ImageDisplayProps {
    source: string;
    record?: any;                   // Optional record prop for direct data binding
    style?: React.CSSProperties;
    placeholder?: string;
    downloadLabel?: string;
    viewLabel?: string;
}

// Helper function to guess the MIME type based on the file extension
const guessMimeType = (imagePath: string): string => {
    const extension = imagePath.split('.').pop()?.toLowerCase();
    switch (extension) {
        case 'jpg':
        case 'jpeg':
            return 'image/jpeg';
        case 'png':
            return 'image/png';
        case 'gif':
            return 'image/gif';
        case 'bmp':
            return 'image/bmp';
        case 'webp':
            return 'image/webp';
        default:
            return 'image/jpeg';
    }
};

const ImageDisplay: React.FC<ImageDisplayProps> = ({
    source,
    record,
    style,
    placeholder,
    downloadLabel = 'Save Image',
    viewLabel = 'View Image',
}) => {
    const theme = useTheme();
    const contextRecord = useRecordContext(); // Default to context if record prop is not provided
    const effectiveRecord = record || contextRecord;
    const imagePath = effectiveRecord?.[source];
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const uploadAreaStyles = {
        height: '250px',
        width: '340px',
        border: `0px solid ${theme.palette.grey[400]}`,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        marginBottom: theme.spacing(1),
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: theme.palette.secondary.main,
        fontWeight: 'bolder',
        '&:hover': {
            borderColor: theme.palette.primary.main,
        },
        '&:focus': {
            borderColor: theme.palette.primary.main,
            boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.25)}`,
        },
    };

    useEffect(() => {
        if (!imagePath) {
            setImageUrl(placeholder || null);
            setLoading(false);
            return;
        }

        const fetchImage = async () => {
            try {
                const { data, error } = await supabase.storage.from('tigon').download(imagePath);
                if (error) throw new Error('Error fetching image: ' + error.message);

                const mimeType = guessMimeType(imagePath);
                const blob = data.slice(0, data.size, mimeType);
                const url = URL.createObjectURL(blob);
                setImageUrl(url);
            } catch (error) {
                console.error(error.message);
                setImageUrl(placeholder || null);
            } finally {
                setLoading(false);
            }
        };

        fetchImage();

        // Cleanup the object URL to release memory
        // return () => {
        //     if (imageUrl) {
        //         URL.revokeObjectURL(imageUrl);
        //     }
        // };
    }, [imagePath, placeholder]);

    const handleDownload = () => {
        if (!imageUrl) return;

        const anchor = document.createElement('a');
        anchor.href = imageUrl;
        anchor.download = imagePath ? imagePath.split('/').pop() || 'downloaded_image' : 'downloaded_image';
        anchor.click();
        URL.revokeObjectURL(anchor.href);
    };

    const handleView = () => {
        if (imageUrl) {
            window.open(imageUrl, '_blank');
        }
    };

    return (
        <Box sx={{ ...uploadAreaStyles, ...style, position: 'relative', textAlign: 'center' }}>
            {loading ? (
                <Typography variant="body2">Loading image...</Typography>
            ) : imageUrl ? (
                <img src={imageUrl} alt="Displayed content" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            ) : (
                <Typography variant="body2">No image available</Typography>
            )}

            {imageUrl && (
                <>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleDownload}
                        sx={{ marginTop: 2, position: 'absolute', bottom: 0, left: 0, width: '50%', borderRadius: 0 }}
                    >
                        {downloadLabel}
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleView}
                        sx={{ marginTop: 2, position: 'absolute', bottom: 0, right: 0, width: '50%', borderRadius: 0 }}
                    >
                        {viewLabel}
                    </Button>
                </>
            )}
        </Box>
    );
};

export default ImageDisplay;
