import React from 'react';
import { Datagrid, TextField, useRecordContext } from 'react-admin';
import { Box, Typography } from '@mui/material';

/**
 * Component to display service fees in a read-only Datagrid format using react-admin.
 */
const ServiceFeeDisplaySection: React.FC = () => {
    const record = useRecordContext(); // Access the record context for the current contract

    // Calculate the total amount
    const totalAmount = record?.contract_service_fees?.filter(x => x.service_fee_id != 41).reduce((acc: number, fee: any) => acc + (fee.negotiated_fee || 0), 0) || 0;

    return (
        <Box sx={{ width: '100%' }}>
            <Datagrid data={record?.contract_service_fees} isRowSelectable={() => false} bulkActionButtons={false}>
                <TextField source="service_name" label="Service" />
                <TextField source="negotiated_fee" label="Negotiated Fee (VND)" />
                <TextField source="description" label="Description" />
            </Datagrid>

            {/* Display the total amount below the service fees */}
            <Typography variant="h6" align="left" sx={{ marginTop: 2 }}>
                Total Amount: {new Intl.NumberFormat().format(totalAmount)} VND
            </Typography>
        </Box>
    );
};

export default ServiceFeeDisplaySection;
