
import CustomerList from './CustomerList';

const CustomerPage = {
    name: 'customers',
    list: CustomerList,
    // create: CustomerCreate,
    // edit: CustomerEdit,
    // show: CustomerShow,
};

export default CustomerPage;
