import React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton, ListProps } from 'react-admin';

const HouseList: React.FC<ListProps> = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="house_name" label="House Name" />
            <TextField source="address" label="Address" />
            <TextField source="ward" label="Ward" />
            <TextField source="district" label="District" />
            <TextField source="city" label="City" />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export default HouseList;
