
import ExpenseList from './ExpenseList';
import ExpenseCreate from './ExpenseCreate';
import ExpenseEdit from './ExpenseEdit';
import ExpenseShow from './ExpenseShow';

const ExpensePage = {
    name: 'expenses',
    list: ExpenseList,
    create: ExpenseCreate,
    edit: ExpenseEdit,
    show: ExpenseShow,
};

export default ExpensePage;
