
import ContractList from './ContractList';
import ContractCreate from './ContractCreate';
import ContractEdit from './ContractEdit';
import ContractShow from './ContractShow';

const ContractPage = {
    name: 'contracts',
    list: ContractList,
    create: ContractCreate,
    edit: ContractEdit,
    show: ContractShow,
};

export default ContractPage;
