import React, { useRef } from 'react';
import { useInput, TextInputProps } from 'react-admin';
import { Box, InputBase, Button } from '@mui/material';
import QRReaderButton from './QRReaderButton'; // Import your existing QRReaderButton
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import CameraIcon from '@mui/icons-material/Camera';
/**
 * QRTextInput component using InputBase from Material-UI and a custom button to trigger QR scanning.
 * The QRReaderButton is invisible and its functionality is triggered via the custom button.
 *
 * @param {QRTextInputProps} props - Props for the component including those for InputBase.
 * @param {number} props.defaultZoom - Default zoom level for the QR code scanning functionality.
 * @param {function} props.onScanSuccess - Callback for when a QR code is successfully scanned.
 * @returns {JSX.Element} An InputBase with a custom button for scanning QR codes.
 */
interface QRTextInputProps extends TextInputProps {
    defaultZoom: number;
    onScanSuccess: (data: string) => void; // Callback for QR scan success
}

const QRTextInput: React.FC<QRTextInputProps> = ({ defaultZoom, onScanSuccess, ...props }) => {
    const qrReaderRef = useRef<any>(null); // Use ref to trigger the QRReaderButton
    const {
        field: { value, onChange },
        isRequired,
        fieldState: { error },
        formState: { isSubmitting },
    } = useInput(props);

    // Handler to update the text input value when QR scan succeeds
    const handleScanSuccess = (scannedData: string) => {
        onChange(scannedData); // Update form state with scanned data
        if (onScanSuccess) {
            onScanSuccess(scannedData); // Optionally trigger additional logic when scan succeeds
        }
    };

    // Programmatically simulate a click on the hidden QRReaderButton when custom button is clicked
    const handleButtonClick = () => {
        if (qrReaderRef.current) {
            qrReaderRef.current.querySelector('button').click(); // Trigger the QRReaderButton's functionality
        }
    };
    return (
        <Paper
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', ml: 1 }}
        >
            <InputBase
                value={value}
                onChange={(e) => onChange(e.target.value)} // Handle input change
                sx={{ ml: 1, flex: 1 }}
                placeholder="Scan or type here..."
                inputProps={{ 'aria-label': 'Scan or type here...' }}
            />
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton color="primary" sx={{ p: '10px' }} aria-label="SCAN QR" onClick={handleButtonClick} >
                <CameraIcon />
            </IconButton>
            {/* Hidden QRReaderButton */}
            <div ref={qrReaderRef} style={{ display: 'none' }}>
                <QRReaderButton
                    onScanSuccess={handleScanSuccess}
                    defaultZoom={defaultZoom}
                />
            </div>
        </Paper>
    );
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* InputBase from Material-UI, bound to form state */}
            <InputBase
                value={value}
                onChange={(e) => onChange(e.target.value)} // Handle input change
                placeholder="Scan or type here..."
                sx={{ border: '1px solid gray', padding: '8px', borderRadius: '4px', flex: 1 }} // Custom styles
                {...props}
            />

            {/* Custom button to trigger QR scanning */}
            <Button
                variant="contained"
                onClick={handleButtonClick} // Trigger QR scanning on button click
                sx={{ ml: 1 }} // Add some spacing between the input and button
            >
                Scan QR
            </Button>

            {/* Hidden QRReaderButton */}
            <div ref={qrReaderRef} style={{ display: 'none' }}>
                <QRReaderButton
                    onScanSuccess={handleScanSuccess}
                    defaultZoom={defaultZoom}
                />
            </div>
        </Box>
    );
};

export default QRTextInput;
