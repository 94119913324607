import React, { } from 'react';
import { Grid } from '@mui/material';
import { TextInput, ArrayInput, SimpleFormIterator, DateInput, FormDataConsumer, useNotify, required } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import idCardPlaceholderFront from './id-card-front.svg';  // Front ID placeholder
import idCardPlaceholderBack from './id-card-back.svg';    // Back ID placeholder
import bikePlaceholder from './bike.svg';    // Back ID placeholder
import ImageFileInput from '../../components/ImageFileInput';  // Import the custom ImageFileInput component
import QRReaderButton from '../../components/QRReaderButton';
// function readFromIdQR(scopedFormData: FormData, data: string): void {

// }
/**
 * Parses a date string in the format "ddmmyyyy" and returns a Date object.
 * 
 * @param {string} dateString - The date string in "ddmmyyyy" format.
 * @returns {Date} - The parsed Date object.
 * 
 * @example
 * const date = parseDateString("25092023");
 * console.log(date); // Output: Mon Sep 25 2023
 */
function parseDateString(dateString: string): Date {
    if (dateString.length !== 8) {
        throw new Error("Invalid date format. Expected 'ddmmyyyy'.");
    }

    const day = parseInt(dateString.slice(0, 2), 10);
    const month = parseInt(dateString.slice(2, 4), 10) - 1; // JavaScript months are 0-based
    const year = parseInt(dateString.slice(4, 8), 10);

    return new Date(year, month, day);
}
const CustomerFormSection: React.FC = () => {
    const { setValue } = useFormContext();  // Use setValue to programmatically update form values
    const notify = useNotify();
    // Watch the room_id to detect changes



    const readFromIdQR = ({ scopedFormData, formData }, result) => {
        var parts = result.split('|');
        if (parts.length < 7) {
            notify('Not a valid QR Code on ID card');
            return;
        }
        var [identityNumber, oldIdNumber, fullName, dob, sex, address, issuedDate] = parts;
        scopedFormData.customer_identity_number = identityNumber;
        scopedFormData.customer_name = fullName;
        scopedFormData.customer_dob = parseDateString(dob);
        scopedFormData.customer_address = address;
        scopedFormData.customer_id_issue_date = parseDateString(issuedDate);
        setValue('contract_customers', formData.contract_customers);
    };


    return (
        <ArrayInput
            source="contract_customers"
            label="Customers"
            // defaultValue={[{ new_customer: true }]}
            defaultValue={[
                { new_customer: true, customer_name: 'John Doe', phone_number: '1234567890', email: 'john@example.com', bike_license_plate: 'AB1234', is_primary: true }
            ]} // Add default "New Customer" with test values
        >
            <SimpleFormIterator disableClear={true} disableAdd={false} disableReordering={true} disableRemove={false}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={2}>
                        <FormDataConsumer>
                            {(data) => (
                                <QRReaderButton defaultZoom={3.2} onScanSuccess={(result) => readFromIdQR(data, result)} />
                            )}
                        </FormDataConsumer>

                    </Grid>
                    {/* Customer Name Input */}
                    <Grid item xs={12} sm={6} md={2}>
                        <TextInput
                            source="customer_name"
                            label="Customer Name"
                            validate={required()}
                            fullWidth
                        />
                    </Grid>

                    {/* Date of Birth - Smaller field */}
                    <Grid item xs={6} md={2}>
                        <DateInput
                            source="customer_dob"
                            label="Date of Birth"
                            validate={required()}
                            fullWidth
                        />
                    </Grid>

                    {/* Identity Number - Smaller field */}
                    <Grid item xs={6} md={2}>
                        <TextInput
                            source="customer_identity_number"
                            label="Identity Number"
                            validate={required()}
                            fullWidth
                        />
                    </Grid>

                    {/* ID Issue Date - Smaller field */}
                    <Grid item xs={6} md={2}>
                        <DateInput
                            source="customer_id_issue_date"
                            label="ID Issue Date"
                            validate={required()}
                            fullWidth
                        />
                    </Grid>

                    {/* Customer Address - Takes moderate space */}
                    <Grid item xs={12} sm={6} md={4}>
                        <TextInput
                            source="customer_address"
                            label="Customer Address"
                            validate={required()}
                            fullWidth
                        />
                    </Grid>

                    {/* Phone Number - Small field */}
                    <Grid item xs={6} md={2}>
                        <TextInput
                            source="phone_number"
                            label="Phone Number"
                            validate={required()}
                            fullWidth
                        />
                    </Grid>

                    {/* Email - Moderate space */}
                    {/* <Grid item xs={6} md={4}>
                        <TextInput
                            source="email"
                            label="Email"
                            fullWidth
                        />
                    </Grid> */}

                    {/* Bike License Plate - Small field */}
                    <Grid item xs={6} md={2}>
                        <TextInput
                            source="bike_license_plate"
                            label="Motorbike License Plate"
                            fullWidth
                        />
                    </Grid>
                    {/* Front and Back ID Areas (Responsive wrapping) */}
                    <Grid container spacing={2} item xs={12}>

                        {/* Front ID Card Image Upload */}
                        <Grid item xs={12} sm={6} md={6}>
                            <ImageFileInput
                                style={{
                                    backgroundImage: `url('${idCardPlaceholderFront}')`,
                                }}
                                source="id_card_front_url"
                                label="Front ID"
                                prefix="id_cards"
                                validate={required()}
                            />
                        </Grid>

                        {/* Back ID Card Image Upload */}
                        <Grid item xs={12} sm={6} md={6}>
                            <ImageFileInput
                                style={{
                                    backgroundImage: `url('${idCardPlaceholderBack}')`,
                                }}
                                source="id_card_back_url"
                                label="Back ID"
                                prefix="id_cards"
                                validate={required()}
                            />
                        </Grid>
                        {/* Back ID Card Image Upload */}
                        <Grid item xs={12} sm={6} md={6}>
                            <ImageFileInput
                                style={{
                                    backgroundImage: `url('${bikePlaceholder}')`,
                                }}
                                source="bike_image_url"
                                label="Motorbike Image"
                                prefix="id_cards"
                            // validate={required()}
                            />
                        </Grid>
                        {/* Back ID Card Image Upload */}
                        <Grid item xs={0} md={0} visibility={'hidden'}>
                            {/* Hidden input for is_primary */}
                            <TextInput
                                source="is_primary"
                                defaultValue={false}  // Default value set to false
                                type="hidden"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </SimpleFormIterator>
        </ArrayInput>
    );
};

export default CustomerFormSection;
