import HousePage from './houses';
import RoomPage from './rooms';
import ServiceFeePage from './service_fees';
import ContractPage from './contracts';
import AssetPage from './assets';
import UtilityUsagesPage from './utility_usages';
import InvoicesPage from './invoices';
import ExpensePage from './expenses';
import CustomerPage from './customers';

const resources = [
    HousePage,
    RoomPage,
    ServiceFeePage,
    ContractPage,
    AssetPage,
    UtilityUsagesPage,
    InvoicesPage,
    ExpensePage,
    CustomerPage
    // RoomLogPage,
    // GuestPage,
    // GuestVehiclePage,
    // CustomerVehiclePage,
    // StorageDurationPage,
];

export default resources;
