import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
    TextInput,
    DateInput,
    NumberInput,
    ReferenceInput,
    AutocompleteInput,
    ArrayInput,
    SimpleFormIterator,
    SelectInput,
} from 'react-admin';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { useDataProvider } from 'react-admin';
import ActiveContractInput from '../../components/ActiveContractInput';
// Function to format numbers with commas for better readability
const formatNumber = (value: number) => {
    return new Intl.NumberFormat().format(value);
};
const ExpenseForm: React.FC = () => {
    const { setValue } = useFormContext();
    const expenseItems = useWatch({ name: 'expense_items' }) || []; // Dynamically watch `expense_items`
    const [open, setOpen] = useState(false);
    const [banks, setBanks] = useState<{ id: string; name: string; short_name: string }[]>([]);
    const [newBeneficiary, setNewBeneficiary] = useState({
        beneficiary_name: '',
        contact_info: '',
        address: '',
        bank_account: '',
        bank_name: '',
    });
    const dataProvider = useDataProvider();

    // Calculate total amount whenever expenseItems change
    useEffect(() => {
        const total = expenseItems.reduce((sum, item) => sum + (item?.amount || 0), 0);
        setValue('amount', total);
    }, [expenseItems, setValue]);

    // Load bank list
    useEffect(() => {
        fetch('https://qr.sepay.vn/banks.json')
            .then((response) => response.json())
            .then((data) => {
                const formattedBanks = data.data.map((bank: any) => ({
                    id: bank.bin, // Use `bin` as the identifier
                    name: bank.name,
                    short_name: bank.short_name,
                }));
                setBanks(formattedBanks);
            });
    }, []);

    // Handle adding a new beneficiary
    const handleAddBeneficiary = async () => {
        try {
            const response = await dataProvider.create('beneficiaries', { data: newBeneficiary });
            setValue('beneficiary_id', response.data.id); // Set the new beneficiary's ID in the form
            setOpen(false);
        } catch (error) {
            console.error('Error creating beneficiary:', error);
        }
    };

    const standardizeName = (name: string) =>
        name
            .trim()
            .replace(/\s+/g, ' ')
            .toLowerCase()
            .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase()); // Converts to Title Case

    return (
        <Grid container spacing={2}>
            {/* Expense Details */}
            <Grid item xs={12} md={6}>
                <DateInput source="expense_date" label="Expense Date" fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
                <ActiveContractInput label="Room" source="contract_id" fullWidth />
            </Grid>
            <Grid item xs={12}>
                <TextInput source="description" label="Description" fullWidth multiline />
            </Grid>
            <Grid item xs={12} md={6}>
                <ReferenceInput
                    source="beneficiary_id"
                    reference="beneficiaries"
                    label="Beneficiary"
                    fullWidth
                    filterToQuery={(searchText: string) => ({ beneficiary_name: searchText })}
                >
                    <AutocompleteInput
                        optionText="beneficiary_name"
                        onCreate={(newBeneficiaryName) => {
                            const standardizedBeneficiaryName = standardizeName(newBeneficiaryName);
                            setNewBeneficiary({
                                ...newBeneficiary,
                                beneficiary_name: standardizedBeneficiaryName,
                            });
                            setOpen(true);
                        }}
                    />
                </ReferenceInput>
            </Grid>

            <Grid item xs={12} md={6}>
                <TextInput source="amount" label="Total Amount" fullWidth disabled
                    parse={(v) => parseInt(v.replaceAll(',', ''), 10)}  // Parse the input value as an integer
                    format={(v) => (v ? formatNumber(v) : '')}  // Format the displayed number
                />
            </Grid>
            {/* Expense Items */}
            <Grid item xs={12}>
                <ArrayInput source="expense_items" label="Expense Items">
                    <SimpleFormIterator>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={4}>
                                <SelectInput
                                    source="category"
                                    label="Category"
                                    choices={[
                                        { id: 'COMMISSION_FEE', name: 'Commission Fee' },
                                        { id: 'FUEL_EXPENSES', name: 'Fuel Expenses' },
                                        { id: 'WATER_UTILITY', name: 'Water Utility' },
                                        { id: 'ELECTRICITY_UTILITY', name: 'Electricity Utility' },
                                        { id: 'SECURITY_SERVICES', name: 'Security Services' },
                                        { id: 'CLEANING_SERVICES', name: 'Cleaning Services' },
                                        { id: 'WASTE_MANAGEMENT', name: 'Waste Management' },
                                        { id: 'EMPLOYEE_SALARIES', name: 'Employee Salaries' },
                                        { id: 'HAO', name: 'Hao' },
                                        { id: 'OTHER', name: 'Other' },
                                        { id: 'COMMISSION_REFUNDED', name: 'Commission Refunded' },
                                        { id: 'DEPOSIT_REFUNDED', name: 'Deposit Refunded' },
                                    ]}
                                    fullWidth

                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TextInput
                                    source="item_name"
                                    label="Description"
                                    fullWidth

                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextInput
                                    source="amount"
                                    label="Amount"
                                    fullWidth
                                    parse={(v) => parseInt(v.replaceAll(',', ''), 10)}  // Parse the input value as an integer
                                    format={(v) => (v ? formatNumber(v) : '')}  // Format the displayed number
                                />
                            </Grid>

                        </Grid>
                    </SimpleFormIterator>
                </ArrayInput>
            </Grid>


            {/* Add Beneficiary Dialog */}
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Add New Beneficiary</DialogTitle>
                <DialogContent>
                    <TextInput
                        source="beneficiary_name"
                        label="Beneficiary Name"
                        fullWidth
                        onChange={(e) =>
                            setNewBeneficiary({ ...newBeneficiary, beneficiary_name: e.target.value })
                        }
                        required
                    />
                    <AutocompleteInput
                        source="bank_name"
                        label="Bank Name"
                        choices={banks}
                        fullWidth
                        filterToQuery={(searchText: string) =>
                            banks.filter(
                                (bank) =>
                                    bank.name.toLowerCase().includes(searchText.toLowerCase()) ||
                                    bank.short_name.toLowerCase().includes(searchText.toLowerCase())
                            )
                        }
                        onChange={(e) => setNewBeneficiary({ ...newBeneficiary, bank_name: e })}
                        required
                    />
                    <TextInput
                        source="bank_account"
                        label="Bank Account"
                        fullWidth
                        onChange={(e) => setNewBeneficiary({ ...newBeneficiary, bank_account: e.target.value })}
                        required
                    />
                    <TextInput
                        source="contact_info"
                        label="Contact Info"
                        fullWidth
                        onChange={(e) =>
                            setNewBeneficiary({ ...newBeneficiary, contact_info: e.target.value })
                        }
                    />
                    <TextInput
                        source="address"
                        label="Address"
                        fullWidth
                        onChange={(e) => setNewBeneficiary({ ...newBeneficiary, address: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button
                        onClick={handleAddBeneficiary}
                        disabled={!newBeneficiary.bank_account || !newBeneficiary.bank_name}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default ExpenseForm;
