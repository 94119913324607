import React from 'react';
import { DateInput, Filter, SelectInput, List, Datagrid, TextField, DateField, NumberField, ReferenceField } from 'react-admin';
import ActiveContractInput from '../../components/ActiveContractInput';
import dayjs from 'dayjs';
// Default filter values with time adjustments
const filterDefaultValues = {
    "expense_date@gte": dayjs().startOf('month').format('YYYY-MM-DDT00:00:00'),
    "expense_date@lte": dayjs().endOf('month').format('YYYY-MM-DDT23:59:59'),
    status: 'PENDING',
};
/**
 * Filter component for InvoiceList, including the contract and status filters.
 */
const ExpenseFilter: React.FC = (props) => (
    <Filter {...props}>
        <ActiveContractInput label="Room" source="contract_id" alwaysOn />
        {/* Status Filter for invoices */}
        <SelectInput
            label="Status"
            source="status"
            choices={[
                { id: 'PENDING', name: 'Pending' },
                { id: 'PAID', name: 'Paid' },
                { id: 'APPROVED', name: 'Approved' },
                { id: 'CANCELLED', name: 'Cancelled' },
                { id: 'REIMBURSED', name: 'Reimbursed' },
                { id: 'DISPUTED', name: 'Disputed' },
            ]}
            alwaysOn
        />
        {/* Expense Date range filters */}
        <DateInput source="expense_date@gte" label="Expense Date From" alwaysOn />
        <DateInput source="expense_date@lte" label="Expense Date To" alwaysOn />
    </Filter>
);
const ExpenseList: React.FC = () => (
    <List
        filters={<ExpenseFilter />}
        filterDefaultValues={filterDefaultValues} // Set default filter values
        sort={{ field: 'expense_date', order: 'DESC' }}
    >
        <Datagrid>
            <ReferenceField label="Room" source="contract_id" reference="contracts" link={false}>
                <ReferenceField source="room_id" reference="rooms">
                    <TextField source="room_name" />
                </ReferenceField>
            </ReferenceField>
            <DateField source="expense_date" label="Date" />
            <NumberField
                source="amount"
                label="Amount"
                options={{ useGrouping: true, maximumFractionDigits: 0 }}
            />
            <TextField source="description" label="Description" />
            <ReferenceField
                source="beneficiary_id"
                reference="beneficiaries"
                label="Beneficiary"
            >
                <TextField source="beneficiary_name" />
            </ReferenceField>
            <TextField source="status" label="Status" />
        </Datagrid>
    </List>
);

export default ExpenseList;
