import React, { useState, useEffect } from 'react';
import { Button, useDataProvider, useRecordContext } from 'react-admin';
import PrintIcon from '@mui/icons-material/Print';

/**
 * PrintButton component - fetches the invoice and associated items, then sends them to a remote print API.
 */
const PrintButton: React.FC<{ invoiceId: number }> = ({ invoiceId }) => {
    const [invoiceItems, setInvoiceItems] = useState<any[]>([]);
    const dataProvider = useDataProvider();
    const record = useRecordContext(); // Access the current invoice data

    // Fetch the invoice items when the component mounts
    useEffect(() => {
        const fetchInvoiceItems = async () => {
            const { data, error } = await dataProvider.getList('invoice_items', {
                filter: { invoice_id: invoiceId },
                pagination: { page: 1, perPage: 1000 },
            });

            if (!error) {
                setInvoiceItems(data);
            } else {
                console.error('Failed to fetch invoice items:', error);
            }
        };

        fetchInvoiceItems();
    }, [invoiceId, dataProvider]);

    // Handle the print operation
    const handlePrint = async () => {
        try {
            // Prepare the data to send to the print API, including the invoice details and items
            const printData = {
                invoiceId: record?.id,
                invoiceDate: record?.invoice_date,
                totalAmount: record?.total_amount,
                status: record?.status,
                items: invoiceItems, // Include all the invoice items
            };

            // Call the remote print API
            const response = await fetch('https://remote-api.example.com/print-invoice', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(printData),
            });

            const pdfBlob = await response.blob();
            const pdfUrl = window.URL.createObjectURL(pdfBlob);
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.setAttribute('download', `invoice_${invoiceId}.pdf`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Failed to print the invoice:', error);
        }
    };

    return (
        <Button label="Print" onClick={handlePrint}>
            <PrintIcon />
        </Button>
    );
};

export default PrintButton;
