import { supabaseDataProvider } from 'ra-supabase';
import { supabaseClient } from './supabase';
import { DataProvider } from 'react-admin';
import { fetchUtils } from 'react-admin';

const baseDataProvider = supabaseDataProvider({
    instanceUrl: 'https://zeypmynsawlqtkgjcjjm.supabase.co',
    apiKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InpleXBteW5zYXdscXRrZ2pjamptIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjY0NzYwNTEsImV4cCI6MjA0MjA1MjA1MX0.jfeQseWJhoP_ovV7qNAV9rtppXcmmAo2M-t4HvcVmps',
    supabaseClient,
});


async function getAccessToken(): string {
    let a = await supabaseClient.auth.getSession();
    return a.data.session.access_token;
    // var a = supabaseClient.changedAccessToken || baseDataProvider.changedAccessToken;
    // return baseDataProvider.changedAccessToken;
}


/**
 * Merges defaultServiceFees and currentServiceFees based on service_fee_id.
 * If a service is in currentServiceFees but not in defaultServiceFees, it is still included.
 * @param defaultServiceFees - The array of default service fees.
 * @param currentServiceFees - The array of current service fees.
 * @returns The merged array of service fees.
 */
function mergeServiceFees(
    defaultServiceFees: ContractServiceFee[],
    currentServiceFees: ContractServiceFee[]
): ContractServiceFee[] {
    // Create a map from currentServiceFees for quick lookup by service_fee_id
    const currentServiceFeeMap = new Map(
        currentServiceFees.map(service => [service.service_fee_id, service])
    );

    // Merge default services and keep existing ones from currentServiceFees
    const mergedServiceFees = defaultServiceFees.map(defaultService => {
        const existingService = currentServiceFeeMap.get(defaultService.service_fee_id);
        return existingService ? Object.assign(defaultService, existingService) : defaultService;
    });

    // Add services that are in currentServiceFees but not in defaultServiceFees
    const missingServices = currentServiceFees.filter(
        service => !defaultServiceFees.some(defaultService => defaultService.service_fee_id === service.service_fee_id)
    );

    return [...mergedServiceFees, ...missingServices];
}

// Extend the data provider with custom methods
export const dataProvider: DataProvider = {
    ...baseDataProvider,
    /**
 * Custom implementation of the `getOne` method in the data provider.
 * 
 * @async
 * @function getOne
 * @param {string} resource - The resource name, e.g., 'contracts'.
 * @param {Object} params - Parameters for the `getOne` method.
 * @param {number} params.id - The ID of the resource to fetch.
 * @param {Object} [params.meta] - Optional metadata used for custom data fetching logic.
 * @param {boolean} [params.meta.useContractDetails] - Flag to indicate if contract details should be fetched via the `get_contract_details` RPC.
 * 
 * @returns {Promise<Object>} - Returns an object with the fetched data.
 * 
 * @throws {Error} Throws an error if fetching contract details fails.
 * 
 * @description 
 * Extends the default `getOne` behavior to conditionally fetch contract details 
 * for the 'contracts' resource by calling a custom Supabase RPC function `get_contract_details`.
 * If the `useContractDetails` flag is provided in the metadata and the resource is 'contracts',
 * it fetches data using `get_contract_details`; otherwise, it falls back to the default `getOne` implementation.
 */
    getOne: async (resource, params) => {
        // Check if the resource is 'contracts' and metadata indicates custom fetch behavior
        if (resource === 'contracts' && params.meta?.useContractDetails) {
            // Call the Supabase RPC function to fetch detailed contract data
            const { data, error } = await supabaseClient.rpc('get_contract_details', {
                p_contract_id: params.id,
            });

            // Handle error by throwing it to be caught by the react-admin error handling
            if (error) {
                throw new Error('Error fetching contract details: ' + error.message);
            }

            if (data.promotion_details) {
                data.discount_amount = data.promotion_details.discount_amount;
                data.promotion_end_date = data.promotion_details.promotion_end_date;
                data.promotion_duration = Math.round((new Date(data.promotion_details.promotion_end_date) - new Date(data.start_date)) / (3600 * 24 * 30 * 1000.0))
            }

            // Step 1: Fetch room details to get room name
            const { data: room } = await supabaseClient.rpc('get_house_and_room_info', { p_room_id: data.room_id });
            const roomName = room.room_name;
            data.room_details = room;

            // Step 2: Fetch all "SERVICE" type service fees
            const serviceFeesResult = await dataProvider.getList('service_fees', {
                pagination: { page: 1, perPage: 100 },
                sort: { field: 'id', order: 'ASC' },
                filter: { fee_type: 'SERVICE' },
            });

            // Step 3: Fetch the rent fee for the selected room_name
            const roomRentFeeResult = await dataProvider.getList('service_fees', {
                pagination: { page: 1, perPage: 1 },
                sort: { field: 'id', order: 'ASC' },
                filter: { service_name: roomName, fee_type: 'RENT' },
            });

            // Step 4: Combine service fees and rent fee
            const defaultServiceFees = [
                ...(roomRentFeeResult.data.length > 0
                    ? [
                        {
                            service_fee_id: roomRentFeeResult.data[0].id,
                            negotiated_fee: roomRentFeeResult.data[0].default_fee,
                            default_fee: roomRentFeeResult.data[0].default_fee,
                            description: roomRentFeeResult.data[0].description,
                            fee_calculation_method: roomRentFeeResult.data[0].fee_calculation_method,
                            fee_type: roomRentFeeResult.data[0].fee_type,
                            service_name: roomRentFeeResult.data[0].service_name
                        },
                    ]
                    : []),
                ...serviceFeesResult.data.map((fee: any) => ({
                    service_fee_id: fee.id,
                    negotiated_fee: fee.default_fee,
                    default_fee: fee.default_fee,
                    description: fee.description,
                    fee_calculation_method: fee.fee_calculation_method,
                    fee_type: fee.fee_type,
                    service_name: fee.service_name
                })),
            ];

            let serviceFees = defaultServiceFees;
            if (!!data?.contract_service_fees) {
                serviceFees = mergeServiceFees(defaultServiceFees, data.contract_service_fees);
            }

            data.contract_service_fees = serviceFees;

            // Return the fetched contract data
            return { data };
        }

        // Default behavior: use the base data provider's getOne for all other resources or contracts without metadata
        return baseDataProvider.getOne(resource, params);
    },
    /**
     * Bulk create method for inserting multiple records into a Supabase table.
     * 
     * @param {string} resource - The name of the resource (Supabase table) to insert into.
     * @param {Object} params - The params object containing the data to insert.
     * @returns {Promise<{ data: any[] }>} - A promise that resolves to the created records.
     */
    bulkCreate: async (resource: string, params: { data: any[] }): Promise<{ data: any[] }> => {
        try {
            // Perform bulk insert using Supabase's insert method
            const { data, error } = await supabaseClient
                .from(resource)
                .insert(params.data)
                .select(); // Fetch and return the inserted records

            if (error) {
                throw new Error(`Error inserting records: ${error.message}`);
            }

            // Return the created records in a format react-admin expects
            return { data };
        } catch (err) {
            // Handle any errors and rethrow
            console.error('Bulk create error:', err);
            throw err;
        }
    },

    /**
     * Custom method to call the 'save_contract' RPC in Supabase
     * @param {string} resource - Resource name, not used here but follows react-admin pattern
     * @param {Object} params - Parameters for saving the contract
     */
    async saveContract(resource: string, params: { id: number, values: any, promotionDetails: any }) {
        const { id, values, promotionDetails } = params;
        if (values.contract_service_fees) {
            for (const fee of values.contract_service_fees) {
                fee.negotiated_fee = fee.negotiated_fee || 0; // assign zero if empty
            }
            // values.contract_service_fees = values.contract_service_fees.filter(f => !!f.negotiated_fee);
        }
        // Call the Supabase RPC function to save the contract
        const { data: v_contract_id, error } = await supabaseClient.rpc('save_contract', {
            p_start_date: values.start_date,
            p_end_date: values.end_date,
            p_description: values.description || ' ',
            p_status: values.status || 'ACTIVE',
            p_room_id: values.room_id,
            p_duration: values.contract_duration,
            p_contract_customers: values.contract_customers,
            p_contract_service_fees: values.contract_service_fees,
            p_electric_meter_reading: values.electric_meter_reading,
            p_water_meter_reading: values.water_meter_reading,
            p_image_url: values.image_url, // Proof of electric meter reading image URL
            p_promotion_details: promotionDetails, // Add promotion details if applicable
            p_contract_id: id,
            p_deposit_amount: values.deposit_amount, // Add deposit amount
            p_commission_fee: values.commission_fee, // Add commission fee
            p_payment_day: values.payment_day, // Add p_payment_day
        });

        if (error) {
            throw new Error(`Error saving contract: ${error.message}`);
        }

        return { data: { id: v_contract_id } }; // Return contract ID as the result
    },
    /**
     * Custom method to generate an invoice by contract ID and cycle.
     * @param {string} resource - Resource name (not used in this custom method).
     * @param {Object} params - Parameters including contractId and cycle.
     * @returns {Promise<{ data: any }>} - A promise that resolves to the response data.
     */
    async generateInvoice(resource: string, params: { contractId: number, cycle: string }): Promise<{ data: any }> {
        const { contractId, cycle } = params;

        const response = await fetchUtils.fetchJson(process.env.REACT_APP_API_URL + '/invoice/create/', {
            method: 'POST',
            body: JSON.stringify({
                contractId,
                cycle,
            }),
            headers: new Headers({
                'Content-Type': 'application/json',
                // 'Origin': 'devsoft.vn',
                'Authorization': `Bearer ${await getAccessToken()}`, // Use your API key
            }),
            mode: 'cors'
        });

        // Handle successful response and return the data
        return { data: response.json };
    },
    /**
     * Custom method to generate an invoice by contract ID and cycle.
     * @param {string} resource - Resource name (not used in this custom method).
     * @param {Object} params - Parameters including contractId and cycle.
     * @returns {Promise<{ data: any }>} - A promise that resolves to the response data.
     */
    async regenerateInvoice(resource: string, params: { contractId: number }): Promise<{ data: any }> {
        const { contractId } = params;

        const response = await fetchUtils.fetchJson(process.env.REACT_APP_API_URL + '/invoice/regenerate/', {
            method: 'POST',
            body: JSON.stringify({
                contractId,
            }),
            headers: new Headers({
                'Content-Type': 'application/json',
                // 'Origin': 'devsoft.vn',
                'Authorization': `Bearer ${await getAccessToken()}`, // Use your API key
            }),
            mode: 'cors'
        });

        // Handle successful response and return the data
        return { data: response.json };
    },
};