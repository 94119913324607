import React, { useState, useEffect } from 'react';
import {
    Create,
    SimpleForm,
    NumberInput,
    DateInput,
    ImageInput,
    ImageField,
    SelectInput,
    useDataProvider,
    useNotify,
    useRedirect,
    required,
} from 'react-admin';
import { Grid } from '@mui/material';
import { supabaseClient as supabase } from "../../core/supabase";
import ImageFileInput from '../../components/ImageFileInput';  // Import the custom ImageFileInput component
import electricMeterPlaceholder from '../../decorations/electric-meter-electricity.svg';
/**
 * UtilityUsageCreate component allows users to create new utility usages.
 * The water_meter_reading is hidden, and Grid is used for better alignment.
 */
const UtilityUsageCreate: React.FC = () => {
    const [latestContracts, setLatestContracts] = useState<any[]>([]);
    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();

    // Fetch rooms with the latest active contracts when the component mounts
    useEffect(() => {
        fetchLatestActiveContracts();
    }, []);

    /**
     * Fetches the latest active contracts with room information.
     */
    const fetchLatestActiveContracts = async () => {
        try {
            const { data, error } = await supabase
                .from('contracts')
                .select('id, room_id, rooms(room_name)')
                .eq('status', 'ACTIVE')
                .order('start_date', { ascending: false });

            if (error) throw error;

            // Filter the data to get the latest contract per room
            const contracts = data.reduce((acc: any, contract: any) => {
                if (!acc.find((item: any) => item.room_id === contract.room_id)) {
                    acc.push(contract);
                }
                return acc;
            }, []);

            // Map room and contract data into a usable form for the SelectInput
            setLatestContracts(
                contracts.map((contract: any) => ({
                    id: contract.id,
                    name: contract.rooms.room_name, // Display room name
                    contractId: contract.id,        // Use contract ID as the value
                }))
            );
        } catch (error) {
            notify('Error fetching latest active contracts', { type: 'warning' });
        }
    };

    /**
     * Validates if the electric meter reading is greater than the latest value in `utility_usages`
     * for the same contract ID.
     * @param {number} value - The electric meter reading input.
     * @param {object} formData - The form data containing the contract_id.
     * @returns {string | undefined} - Returns an error message if validation fails, or undefined if successful.
     */
    const validateElectricMeterReading = async (value: number, formData: any) => {
        if (!formData.contract_id) return 'Contract ID is required';

        try {
            const { data, error } = await supabase
                .from('utility_usages')
                .select('electric_meter_reading')
                .eq('contract_id', formData.contract_id)
                .order('month_year', { ascending: false })
                .limit(1);

            if (error) throw error;

            const latestReading = data[0]?.electric_meter_reading || 0;

            if (value <= latestReading) {
                return `The electric meter reading should be greater than the last recorded value (${latestReading}).`;
            }
        } catch (error) {
            return 'Error validating electric meter reading';
        }
    };

    /**
     * Handles the form submission for creating a new utility usage.
     * @param {any} formData - The data submitted by the form.
     */
    const handleSave = async (formData: any) => {
        try {
            await dataProvider.create('utility_usages', { data: formData });
            notify('Utility usage created successfully', { type: 'success' });
            redirect('list', 'utility_usages');
        } catch (error) {
            notify('Error creating utility usage', { type: 'warning' });
        }
    };

    // Helper function to get today's date as default for `month_year`
    const getTodayDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0]; // Returns YYYY-MM-DD
    };

    return (
        <Create>
            <SimpleForm onSubmit={handleSave}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        {/* Contract SelectInput which shows room names */}
                        <SelectInput
                            source="contract_id"
                            label="Room"
                            choices={latestContracts.map(contract => ({
                                id: contract.contractId,  // Value is the contract ID
                                name: contract.name,      // Display is the room name
                            }))}
                            validate={required()}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <DateInput
                            source="month_year"
                            label="Month/Year"
                            defaultValue={getTodayDate()} // Set today's date as default
                            validate={required()}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <NumberInput
                            source="electric_meter_reading"
                            label="Electric Meter Reading"
                            validate={[required(), validateElectricMeterReading]} // Validation for electric meter reading
                            fullWidth
                        />
                    </Grid>

                    {/* Water meter reading is hidden */}
                    <Grid item xs={12} sm={6} style={{ display: 'none' }}>
                        <NumberInput source="water_meter_reading" label="Water Meter Reading" fullWidth defaultValue={0} />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                        <ImageFileInput
                            style={{
                                backgroundImage: `url('${electricMeterPlaceholder}')`,
                            }}
                            source="image_url"
                            label="Proof Image"
                            prefix="id_cards"
                            validate={required()}
                        />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    );
};

export default UtilityUsageCreate;
