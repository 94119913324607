import React, { useState } from 'react';
import { Button, useDataProvider } from 'react-admin';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import VoidIcon from '@mui/icons-material/DeleteOutline';
import ConfirmButton from '@mui/icons-material/Check';
import CancelButton from '@mui/icons-material/Cancel';

const VoidButton: React.FC<{ invoiceId: number; refresh: () => void }> = ({ invoiceId, refresh }) => {
    const [open, setOpen] = useState(false);
    const [confirmText, setConfirmText] = useState('');
    const dataProvider = useDataProvider();

    // Open confirm dialog for voiding
    const handleVoid = () => setOpen(true);

    // Handle void confirmation
    const handleConfirmVoid = async () => {
        if (confirmText === 'void') {
            await dataProvider.update('invoices', {
                id: invoiceId,
                data: { status: 'CANCELLED' },
                previousData: {}
            });
            refresh(); // Refresh the page after voiding
        }
        setOpen(false);
    };

    return (
        <>
            <Button label="Void" onClick={handleVoid}>
                <VoidIcon />
            </Button>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Confirm Void</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To void this invoice, please type "void" in the box below.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Type 'void' to confirm"
                        fullWidth
                        value={confirmText}
                        onChange={(e) => setConfirmText(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>
                        <CancelButton /> Cancel
                    </Button>
                    <Button onClick={handleConfirmVoid}>
                        <ConfirmButton /> Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default VoidButton;
