import React, { useState, useEffect } from 'react';
import { useRecordContext, useDataProvider, Button, useNotify } from 'react-admin';
import { supabaseClient as supabase } from "../../../core/supabase";
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, CircularProgress } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';

const RegenerateInvoiceButton: React.FC = () => {
    const record = useRecordContext(); // Access the current contract record
    const dataProvider = useDataProvider();
    const [hasPendingInvoice, setHasPendingInvoice] = useState(false);
    const [loading, setLoading] = useState(false); // Loading state for the button
    const notify = useNotify();
    const navigate = useNavigate();
    useEffect(() => {
        if (record) {
            // Check if there are any pending invoices
            dataProvider.getList('invoices', {
                filter: { contract_id: record.id, status: 'PENDING' },
                pagination: { page: 1, perPage: 1 },
                sort: { field: 'id', order: 'ASC' },
            }).then((response) => {
                setHasPendingInvoice(response.data.length > 0);
            }).catch(async () => {
                // Fallback to Supabase if the DataProvider fails
                const { data, error } = await supabase
                    .from('invoices')
                    .select('id')
                    .eq('contract_id', record.id)
                    .eq('status', 'PENDING')
                    .limit(1);
                if (!error) setHasPendingInvoice(data?.length > 0);
            });
        }
    }, [record, dataProvider]);

    const handleRegenerateInvoices = async () => {
        setLoading(true); // Start loading
        try {
            // Regenerate invoices
            await dataProvider.regenerateInvoice('invoices', {
                contractId: record.id,
            });
            notify('Regenerated invoices successfully', 'info');
            navigate('/invoices?displayedFilters={}&filter=' + encodeURIComponent(`{"contract_id":${record.id}}`));
        } catch (error) {
            notify('Error regenerating invoices', 'warning');
        } finally {
            setLoading(false); // Stop loading after completion
        }
    };

    if (!hasPendingInvoice) return null;

    return (
        <Box>
            <Button
                onClick={handleRegenerateInvoices}
                label="Regenerate Invoices"
                disabled={loading} // Disable the button when loading
            >
                {loading ? <CircularProgress size={20} /> : <RefreshIcon />}
            </Button>
        </Box>
    );
};

export default RegenerateInvoiceButton;
