import React, { } from 'react';
import { Edit, SimpleForm, TextInput, NumberInput, ReferenceInput, SelectInput, EditProps } from 'react-admin';

const RoomEdit: React.FC<EditProps> = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="room_name" label="Room Name" />
                <ReferenceInput source="house_id" reference="houses" label="House">
                    <SelectInput optionText="house_name" />
                </ReferenceInput>
                <NumberInput source="area" label="Area (m²)" />
                <NumberInput source="floor" label="Floor" />
                <NumberInput source="capacity" label="Capacity" />
                <NumberInput source="allowed_parking_lots" label="Allowed Parking Lots" />
                <NumberInput source="available_parking_lots" label="Available Parking Lots" />
                <NumberInput source="duplex_area" label="Duplex Area (m²)" />
                <SelectInput source="status" choices={[
                    { id: 'OUT OF SERVICE', name: 'Out of Service' },
                    { id: 'AVAILABLE', name: 'Available' },
                    { id: 'TERMINATING', name: 'Terminating' },
                    { id: 'OCCUPIED', name: 'Occupied' },
                    { id: 'INVOICE AVAILABLE', name: 'Invoice Available' },
                    { id: 'OVERDUE', name: 'Overdue' },
                ]} />
                <TextInput source="room_image" label="Room Image URL" />
                <TextInput source="description" label="Description" multiline />
            </SimpleForm>
        </Edit>

    );
};

export default RoomEdit;
