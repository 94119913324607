import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useInput, InputProps, FieldTitle } from 'react-admin';
import { supabaseClient as supabase } from '../core/supabase';
import { useTheme, alpha } from '@mui/material/styles';
import { useWatch, useFormContext } from 'react-hook-form';

interface ImageFileInputProps extends InputProps {
    style?: React.CSSProperties;  // Accept custom style
}

const ImageFileInput: React.FC<ImageFileInputProps> = (props) => {

    const theme = useTheme();
    const {
        field,
        isRequired,
        fieldState,
        prefix,
        id,
    } = useInput(props);  // Connect to react-admin's form system

    const { setValue } = useFormContext();  // Use setValue to programmatically update form values
    // Watch the room_id to detect changes
    const room_id = useWatch({ name: 'room_id' });

    const [imagePreview, setImagePreview] = useState<string | null>(field.value || null);
    const [loading, setLoading] = useState<boolean>(false);
    // Download the image when editing a contract
    useEffect(() => {
        const downloadImage = async () => {
            if (field.value) {
                try {
                    const { data, error } = await supabase
                        .storage
                        .from('tigon')
                        .download(field.value);

                    if (error) {
                        throw new Error('Error downloading image: ' + error.message);
                    }

                    const url = URL.createObjectURL(data);
                    setImagePreview(url);
                } catch (error) {
                    console.error('Error downloading image:', error);
                }
            }
        };

        downloadImage();
    }, [field.value, supabase]);

    const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement> | ClipboardEvent) => {
        let file: File | null = null;

        if ('clipboardData' in e) {
            const items = e.clipboardData?.items;
            if (items) {
                for (let i = 0; i < items.length; i++) {
                    if (items[i].type.indexOf('image') !== -1) {
                        file = items[i].getAsFile();
                        break;
                    }
                }
            }
        } else {
            file = e.target.files ? e.target.files[0] : null;
        }

        if (file) {
            const filePreviewUrl = URL.createObjectURL(file);
            setImagePreview(filePreviewUrl);

            const fileName = `${prefix || 'uploads'}/${room_id}/${new Date().toISOString()}-${file.name}`;
            try {
                setLoading(true);
                const { data, error } = await supabase.storage
                    .from('tigon')  // Adjust bucket name accordingly
                    .upload(fileName, file);

                if (error) {
                    throw new Error('Error uploading image: ' + error.message);
                }

                const uploadedImageUrl = data?.path || '';
                field.onChange(uploadedImageUrl);  // Set form field value
            } catch (error) {
                console.error('Image upload error:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    const uploadAreaStyles = {
        height: '250px',
        width: '340px',
        border: `0px solid ${theme.palette.grey[400]}`,  // Default border
        borderRadius: theme.shape.borderRadius,  // Default border-radius like TextInput
        padding: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        marginBottom: theme.spacing(1),
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: theme.palette.secondary.main,
        fontWeight: 'bolder',
        '&:hover': {
            borderColor: theme.palette.primary.main,  // Hover effect

        },
        '&:focus': {
            borderColor: theme.palette.primary.main,  // Focus effect
            boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.25)}`,  // Focus shadow
        },
    };

    useEffect(() => {
        const handlePaste = (e: ClipboardEvent) => {
            handleImageUpload(e);
        };

        window.addEventListener('paste', handlePaste);
        return () => {
            window.removeEventListener('paste', handlePaste);
        };
    }, []);

    return (
        <Box>
            {props.label && props.label.length > 0 && (<Typography variant="subtitle2" gutterBottom>
                <FieldTitle label={props.label} source={props.source} isRequired={isRequired} />
            </Typography>)}

            <div
                style={{
                    ...uploadAreaStyles,
                    ...props.style,  // Apply custom styles from the parent component
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    position: 'relative',
                    overflow: 'hidden',
                }}
            >
                {imagePreview ? (
                    <img src={imagePreview} alt="Image preview" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                ) : (
                    <Typography variant="body2" align="center" color='maroon'>Click to upload or paste image</Typography>
                )}

                <input
                    id={id}
                    name={field.name}
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleImageUpload}
                />

                <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                        textAlign: 'center',
                        borderRadius: 0,
                    }}
                    onClick={() => document.getElementById(id)?.click()}
                >
                    {loading ? 'Uploading...' : 'Upload Image'}
                </Button>
            </div>

            {fieldState.invalid && fieldState.error?.message && (
                <Typography variant="caption" color="error">
                    {fieldState.error.message}
                </Typography>
            )}
        </Box>
    );
};

export default ImageFileInput;
