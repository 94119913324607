import React from 'react';
import { Edit } from 'react-admin';
import ContractForm from './ContractForm';

const ContractEdit: React.FC = () => (
    <Edit>
        <ContractForm />
    </Edit>
);

export default ContractEdit;
