
import React from 'react';
import { Show, SimpleShowLayout, TextField, ShowProps, TopToolbar, EditButton, useRecordContext } from 'react-admin';
import CustomActions from './CustomActions'; // Import the CustomActions component
const RoomShowActions: React.FC = () => {
    const record = useRecordContext();

    if (!record) return null;

    return (
        <TopToolbar>
            <EditButton />
        </TopToolbar>
    );
};
const RoomShow: React.FC<ShowProps> = (props) => (
    <Show actions={<CustomActions />}  {...props}>
        <SimpleShowLayout>
            <TextField source="room_name" label="Room Name" />
            <TextField source="house_id" label="House ID" />
            <TextField source="guest_id" label="Guest ID" />
            <TextField source="area" label="Area (m²)" />
            <TextField source="floor" label="Floor" />
            <TextField source="capacity" label="Capacity" />
            <TextField source="allowed_parking_lots" label="Allowed Parking Lots" />
            <TextField source="available_parking_lots" label="Available Parking Lots" />
            <TextField source="duplex_area" label="Duplex Area (m²)" />
            <TextField source="status" label="Status" />
            <TextField source="room_image" label="Room Image URL" />
            <TextField source="description" label="Description" />
        </SimpleShowLayout>
    </Show>
);

export default RoomShow;
