import React from 'react';
import { supabaseClient as supabase } from '../../core/supabase'; // Supabase client
import { Button, useNotify } from 'react-admin'; // Import the notify hook
import PrintIcon from '@mui/icons-material/Print';
import { useFormContext, useFormState } from 'react-hook-form';
import dayjs from 'dayjs';
const formatDate = (date: string | Date) => {
    if (!date) {
        return null;
    }
    return dayjs(date).format('ngày DD [tháng] MM [năm] YYYY');
};
/**
 * AssetPrintButton component that shows a button for viewing the invoice
 * @param {Object} props - Component props
 * @param {string} props.publicInvoiceId - The ID of the invoice
 */
const AssetPrintButton: React.FC = () => {
    const notify = useNotify(); // Initialize notify hook
    const { getValues } = useFormContext(); // Access form context to get values

    const handlePrint = async () => {
        const values = getValues(); // Get all form values, including assets and room details
        const assets = values.assets || [];
        const roomName = values.room_name || document.querySelector('input[name=room]').value;
        const { data, error } = await supabase
            .from('contracts')
            .select('start_date')
            .eq('room_id', values.room)
            .eq('status', 'ACTIVE')
            .single(); // Use .single() if you expect only one active contract


        if (error) throw error;

        const start_date = data.start_date;

        // Group assets by asset_type and calculate quantity and sum of prices
        const groupedAssets = assets.reduce((acc, asset) => {
            const { asset_type, price } = asset;
            if (!acc[asset_type]) {
                acc[asset_type] = { quantity: 0, totalPrice: 0 };
            }
            acc[asset_type].quantity += 1;
            acc[asset_type].totalPrice += price || 0;
            acc[asset_type].price = acc[asset_type].price || price;
            return acc;
        }, {});

        // Sort grouped assets by asset_type
        const sortedAssetRows = Object.entries(groupedAssets)
            .sort(([typeA], [typeB]) => typeA.localeCompare(typeB)) // Sort alphabetically by asset_type
            .map(([assetType, data], index) => `
                <tr>
                    <td>${index + 1}</td>
                    <td>${assetType}</td>
                    <td>${data.quantity}</td>
                    <td>${data.price.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</td>
                    <td>${data.totalPrice.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</td>
                </tr>
            `).join('');

        // Construct HTML content
        const htmlContent = `
            <html>
                <head>
                    <title>Asset Report</title>
                    <style>
                        body { font-family: Arial, sans-serif; margin: 20px; }
                        h1 { color: #333; }
                        table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
                        th, td { padding: 10px; border: 1px solid #ddd; text-align: left; }
                        th { background-color: #f4f4f4; }
                        .signature { margin-top: 40px; }
                    </style>
                </head>
                <body>
                    <h1>Danh sách thiết bị phòng: ${roomName}</h1>
                    <table>
                        <thead>
                            <tr>
                                <th>STT</th>
                                <th>Loại thiết bị</th>
                                <th>Số lượng</th>
                                <th>Đơn giá</th>
                                <th>Thành tiền</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${sortedAssetRows}
                        </tbody>
                    </table>
                    <div class="signature">
                        <p>Khách hàng ký nhận: _________________________ ${formatDate(start_date)} </p>
                    </div>
                </body>
            </html>
        `;

        // Open a new tab and write the HTML content
        const newWindow = window.open('', '_blank');
        newWindow.document.write(htmlContent);
        newWindow.document.close(); // Close document to render content
    };



    return (
        <Button variant="contained" color="primary" onClick={handlePrint}
            label='Print'
            size="medium"
            sx={{ ml: 2 }}
        >
            <PrintIcon />
        </Button>
    );
};

export default AssetPrintButton;
