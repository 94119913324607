import React, { useEffect, useState } from 'react';
import { Edit, SimpleForm, useNotify, useRedirect, useDataProvider } from 'react-admin';
import { CircularProgress, Grid } from '@mui/material';
import ExpenseForm from './ExpenseForm'; // Assuming ExpenseForm is in the same directory
import { useParams } from 'react-router-dom';
const ExpenseEdit: React.FC = (props) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();

    const [expenseData, setExpenseData] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    useEffect(() => {
        const fetchExpenseData = async () => {
            try {
                // Fetch the main expense record
                const expense = await dataProvider.getOne('expenses', { id });

                // Fetch linked expense items
                const { data: items } = await dataProvider.getList('expense_items', {
                    filter: { expense_id: id },
                    pagination: { page: 1, perPage: 100 },
                    sort: { field: 'id', order: 'ASC' },
                });

                // Combine expense record with its items
                setExpenseData({ ...expense.data, expense_items: items });
                setLoading(false);
            } catch (error) {
                console.error('Error loading expense data:', error);
                notify('Error loading expense data', { type: 'error' });
                setLoading(false);
            }
        };

        fetchExpenseData();
    }, [id, dataProvider, notify]);

    const handleSave = async (data: any) => {
        try {
            const { expense_items = [], ...expenseData } = data;

            for (const item of expense_items) {
                item.amount = item.amount || 0;
            }
            // Ensure amount is calculated based on expense_items
            const totalAmount = expense_items.reduce((sum, item) => sum + item.amount, 0);
            expenseData.amount = totalAmount;

            // Update the expense
            await dataProvider.update('expenses', {
                id: data.id,
                data: expenseData,
                previousData: {}
            });

            // Update the expense items
            // Delete existing items and recreate new ones to ensure consistency
            const itemsWithExpenseId = expense_items.map((item) => ({
                ...item,
                expense_id: data.id,
            }));
            await Promise.all(
                itemsWithExpenseId.map((item) =>
                    item.id
                        ? dataProvider.update('expense_items', { id: item.id, data: item, previousData: {} })
                        : dataProvider.create('expense_items', { data: item })
                )
            );

            notify('Expense updated successfully', { type: 'success' });
            redirect('list', 'expenses');
        } catch (error) {
            console.error('Error updating expense:', error);
            notify(`Error: ${error.message}`, { type: 'error' });
        }
    };

    if (loading) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '50vh' }}>
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <Edit {...props}>
            <SimpleForm onSubmit={handleSave} record={expenseData}>
                <ExpenseForm />
            </SimpleForm>
        </Edit>
    );
};

export default ExpenseEdit;
