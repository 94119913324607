
import React from 'react';
import { Show, SimpleShowLayout, TextField, ShowProps } from 'react-admin';

const ServiceFeeShow: React.FC<ShowProps> = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="service_name" label="Service Name" />
            <TextField source="fee_type" label="Fee Type" />
            <TextField source="default_fee" label="Default Fee" />
            <TextField source="description" label="Description" />
        </SimpleShowLayout>
    </Show>
);

export default ServiceFeeShow;
