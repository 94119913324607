import React from 'react';
import {
    List,
    useListContext,
    Filter,
    SelectInput,
    DateInput
} from 'react-admin';
import dayjs from 'dayjs';

import { Table, TableHead, TableRow, TableCell, TableBody, TableFooter } from '@mui/material';
import ActiveContractInput from '../../components/ActiveContractInput';
interface ReportRecord {
    room_name: string;
    rent_fee: number;
    common_service_fee: number;
    parking_fee: number;
    occupancy_surcharge: number;
    water_fee: number;
    last_billed_electric_reading: number;
    current_electric_reading: number;
    total_amount: number;
    bike_count: number;
    customer_count: number;
    contract_age_in_days: number;
    electric_fee: number;
    start_date: Date;
    status: string; // Added field for invoice status
    invoice_date: Date; // Added field for invoice date
    electric_usage: number; // Added field for electric usage
    water_usage: number; // Added field for water usage
}


// Utility function to format numbers
const formatNumber = (value: number | null | undefined): string => {
    if (value === null || value === undefined || value === 0) {
        return '';
    }
    return value.toLocaleString('en-US');
};


const CustomTable: React.FC = () => {
    const { data, isLoading } = useListContext<ReportRecord>();

    if (isLoading || !data) return <p>Loading...</p>;

    // Calculate totals dynamically
    const totals = data.reduce(
        (acc, record) => ({
            rent_fee: acc.rent_fee + record.rent_fee,
            common_service_fee: acc.common_service_fee + record.common_service_fee,
            parking_fee: acc.parking_fee + record.parking_fee,
            occupancy_surcharge: acc.occupancy_surcharge + record.occupancy_surcharge,
            water_fee: acc.water_fee + record.water_fee,
            electric_fee: acc.electric_fee + record.electric_fee,
            last_billed_electric_reading: acc.last_billed_electric_reading + record.last_billed_electric_reading,
            current_electric_reading: acc.current_electric_reading + record.current_electric_reading,
            total_amount: acc.total_amount + record.total_amount,
            bike_count: acc.bike_count + record.bike_count,
            customer_count: acc.customer_count + record.customer_count,
        }),
        {
            customer_count: 0,
            bike_count: 0,
            rent_fee: 0,
            common_service_fee: 0,
            parking_fee: 0,
            occupancy_surcharge: 0,
            water_fee: 0,
            electric_fee: 0,
            last_billed_electric_reading: 0,
            current_electric_reading: 0,
            total_amount: 0,
        }
    );

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Room Name</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>Customer Count</TableCell>
                    <TableCell>Bike Count</TableCell>
                    <TableCell>Parking Fee</TableCell>
                    <TableCell>Rent Fee</TableCell>
                    <TableCell>Common Service Fee</TableCell>
                    <TableCell>Occupancy Surcharge</TableCell>
                    <TableCell>Water Fee</TableCell>
                    <TableCell>Electric Fee</TableCell>
                    <TableCell>Total Amount</TableCell>
                    <TableCell>Last Electric Reading</TableCell>
                    <TableCell>Current Electric Reading</TableCell>
                    <TableCell>Age (days)</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((record, index) => (
                    <TableRow key={index}>
                        <TableCell>{record.room_name}</TableCell>
                        <TableCell>{new Date(record.start_date).toDateString()}</TableCell>
                        <TableCell>{formatNumber(record.customer_count)}</TableCell>
                        <TableCell>{formatNumber(record.bike_count)}</TableCell>
                        <TableCell>{formatNumber(record.parking_fee)}</TableCell>
                        <TableCell>{formatNumber(record.rent_fee)}</TableCell>
                        <TableCell>{formatNumber(record.common_service_fee)}</TableCell>
                        <TableCell>{formatNumber(record.occupancy_surcharge)}</TableCell>
                        <TableCell>{formatNumber(record.water_fee)}</TableCell>
                        <TableCell>{formatNumber(record.electric_fee)}</TableCell>
                        <TableCell>{formatNumber(record.total_amount)}</TableCell>
                        <TableCell>{formatNumber(record.last_billed_electric_reading)}</TableCell>
                        <TableCell>{formatNumber(record.current_electric_reading)}</TableCell>
                        <TableCell>{formatNumber(record.contract_age_in_days)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell>{formatNumber(totals.customer_count)}</TableCell>
                    <TableCell>{formatNumber(totals.bike_count)}</TableCell>
                    <TableCell>{formatNumber(totals.parking_fee)}</TableCell>
                    <TableCell>{formatNumber(totals.rent_fee)}</TableCell>
                    <TableCell>{formatNumber(totals.common_service_fee)}</TableCell>
                    <TableCell>{formatNumber(totals.occupancy_surcharge)}</TableCell>
                    <TableCell>{formatNumber(totals.water_fee)}</TableCell>
                    <TableCell>{formatNumber(totals.electric_fee)}</TableCell>
                    <TableCell>{formatNumber(totals.total_amount)}</TableCell>
                    <TableCell>{formatNumber(totals.last_billed_electric_reading)}</TableCell>
                    <TableCell>{formatNumber(totals.current_electric_reading)}</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableFooter>
        </Table>
    );
};
// Default filter values with time adjustments
const filterDefaultValues = {
    "invoice_date@gte": dayjs().startOf('month').format('YYYY-MM-DDT00:00:00'),
    "invoice_date@lte": dayjs().endOf('month').format('YYYY-MM-DDT23:59:59'),
    status: 'PENDING',
};
const InvoiceFilter: React.FC = (props) => (
    <Filter {...props}>
        <ActiveContractInput label="Room" source="contract_id" alwaysOn />
        {/* Status Filter for invoices */}
        <SelectInput
            label="Status"
            source="status"
            choices={[
                { id: 'PENDING', name: 'Pending' },
                { id: 'PAID', name: 'Paid' },
                { id: 'OVERDUE', name: 'Overdue' },
                { id: 'CANCELLED', name: 'Cancelled' },
                { id: 'REFUNDED', name: 'Refunded' },
                { id: 'DISPUTED', name: 'Disputed' },
            ]}
            alwaysOn
        />
        {/* Invoice date range filters */}
        <DateInput source="invoice_date@gte" label="Invoice Date From" alwaysOn />
        <DateInput source="invoice_date@lte" label="Invoice Date To" alwaysOn />

    </Filter>
);

const MonthlyInvoiceReport: React.FC = () => {
    return (
        <List
            title="Monthly Invoices Report"
            resource="monthly_invoices"
            sort={{ field: 'room_name', order: 'ASC' }} // Default sort by room_name
            perPage={50} // Set default perPage to 50
            bulkActionButtons={false}
            filters={<InvoiceFilter />}
            filterDefaultValues={filterDefaultValues} // Set default filter values
        >
            <CustomTable />
        </List>
    );
};

export default MonthlyInvoiceReport;
