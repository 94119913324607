import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { ReferenceField, TextField, DateField, Labeled, useRecordContext } from 'react-admin';
import ImageDisplay from '../../../components/ImageDisplay';  // Import the custom ImageFileInput component
import electricMeterPlaceholder from '../../../decorations/electric-meter-electricity.svg';    // Back ID placeholder
const inputStyles = { marginTop: '8px' };

const ContractShowSection: React.FC = () => {
    const record = useRecordContext();  // Access the contract data for display

    if (!record) return null;  // If no record is found, return nothing

    // Convert contract duration ID to human-readable value
    const contractDurationDisplay = (duration: string) => {
        switch (duration) {
            case '6 months': return '6 months';
            case '1 year': return '1 year';
            case 'other': return 'Other';
            default: return 'No';
        }
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>

                {/* Left: Proof of Electric Meter Reading Display */}
                <Grid item xs={12} md={4} lg={4}>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="center">
                            <Labeled label="Electric Meter Reading">
                                <TextField source="electric_meter_reading" record={record} />
                            </Labeled>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="center">
                            <ImageDisplay
                                style={{
                                    backgroundImage: `url('${electricMeterPlaceholder}')`,
                                }}
                                source="image_url"
                                label=""
                                prefix="meters"
                            />

                        </Box>
                    </Grid>
                </Grid>

                {/* Right: Display Contract Information */}
                <Grid item xs={12} md={8} lg={8}>
                    <Grid container spacing={2}>

                        {/* Room Name */}
                        <Grid item xs={6} sm={4} md={3}>
                            <Labeled label="Room">
                                <ReferenceField reference="rooms" source="room_id" link="show" record={record}>
                                    <TextField source="room_name" />
                                </ReferenceField>
                            </Labeled>
                        </Grid>

                        {/* Contract Duration */}
                        <Grid item xs={6} sm={4} md={3}>
                            <Labeled label="Contract Duration">
                                <TextField
                                    source="contract_duration"
                                    record={record}
                                    render={() => contractDurationDisplay(record.contract_duration)}
                                />
                            </Labeled>
                        </Grid>

                        {/* Start Date */}
                        <Grid item xs={6} sm={4} md={3}>
                            <Labeled label="Start Date">
                                <DateField source="start_date" record={record} />
                            </Labeled>
                        </Grid>

                        {/* End Date */}
                        <Grid item xs={6} sm={4} md={3}>
                            <Labeled label="End Date">
                                <DateField source="end_date" record={record} />
                            </Labeled>
                        </Grid>

                        {/* Payment Day */}
                        <Grid item xs={6} sm={4} md={3}>
                            <Labeled label="Payment Day">
                                <TextField source="payment_day" record={record} />
                            </Labeled>
                        </Grid>

                        {/* Deposit Amount */}
                        <Grid item xs={6} sm={4} md={3}>
                            <Labeled label="Deposit Amount">
                                <TextField source="deposit_amount" record={record} />
                            </Labeled>
                        </Grid>

                        {/* Commission Fee */}
                        <Grid item xs={6} sm={4} md={3}>
                            <Labeled label="Commission Fee">
                                <TextField source="commission_fee" record={record} />
                            </Labeled>
                        </Grid>

                        {/* Promotion Duration */}
                        <Grid item xs={6} sm={4} md={3}>
                            <Labeled label="Promotion Duration (Months)">
                                <TextField source="promotion_duration" record={record} />
                            </Labeled>
                        </Grid>

                        {/* Discount Amount, displayed only if promotion is active */}
                        {record.promotion_duration > 0 && (
                            <Grid item xs={6} sm={4} md={3}>
                                <Labeled label="Discount Amount">
                                    <TextField source="discount_amount" record={record} />
                                </Labeled>
                            </Grid>
                        )}

                        {/* Water Meter Reading (hidden field) */}
                        <Grid item xs={0} md={0} style={{ visibility: 'hidden' }}>
                            <Labeled label="Water Meter Reading">
                                <TextField source="water_meter_reading" defaultValue={0} record={record} />
                            </Labeled>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ContractShowSection;
