
import ServiceFeeList from './ServiceFeeList';
import ServiceFeeCreate from './ServiceFeeCreate';
import ServiceFeeEdit from './ServiceFeeEdit';
import ServiceFeeShow from './ServiceFeeShow';

const ServiceFeePage = {
    name: 'service_fees',
    list: ServiceFeeList,
    create: ServiceFeeCreate,
    edit: ServiceFeeEdit,
    show: ServiceFeeShow,
};

export default ServiceFeePage;
