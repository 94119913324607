// in App.js
import * as React from "react";
import { Admin, CustomRoutes, Resource, Menu, Layout } from 'react-admin';
import { LoginPage, SetPasswordPage, ForgotPasswordPage } from 'ra-supabase';
import { BrowserRouter, Route } from 'react-router-dom';
import { dataProvider } from './core/dataProvider';
import { authProvider } from './core/authProvider';
import RoomAssets from './pages/rooms/RoomAssets';
import ActiveContractsReport from './pages/report/ActiveContractsReport';
import MonthlyInvoiceReport from './pages/report/MonthlyInvoice';
import LabelIcon from '@mui/icons-material/Label';
import pages from './pages';
import Submenu from './components/Submenu';
export const MyMenu = () => (
    <Menu>
        <Menu.ResourceItems />
        <Submenu text="Reports" icon={<LabelIcon />}>
            <Menu.Item to="/report/active-contracts" primaryText="Active Contracts" />
            <Menu.Item to="/report/monthly-invoices" primaryText="Monthly Invoices" />
        </Submenu>
    </Menu>
);
const MyLayout = ({ children }) => (
    <Layout menu={MyMenu}>
        {children}
    </Layout>
);
export default function App() {
    return (
        <Admin
            dataProvider={dataProvider}
            authProvider={authProvider}
            layout={MyLayout}
            loginPage={LoginPage}
        >
            {permissions => [
                <CustomRoutes noLayout>
                    <Route
                        path={SetPasswordPage.path}
                        element={<SetPasswordPage />}
                    />
                    <Route
                        path={ForgotPasswordPage.path}
                        element={<ForgotPasswordPage />}
                    />
                </CustomRoutes>,
                <CustomRoutes>
                    <Route path="/rooms/:roomId/assets" element={<RoomAssets />} />
                    <Route path="/report/active-contracts" element={<ActiveContractsReport />} />
                    <Route path="/report/monthly-invoices" element={<MonthlyInvoiceReport />} />
                </CustomRoutes>,
                ...pages.filter(page => !!permissions && permissions.some(permission => permission.resource === '*' || permission.resource === page.name)).map((page, i) => (<Resource key={i} {...page} />))
            ]}
        </Admin>
    );
} 