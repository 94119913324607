import React, { useState } from 'react';
import { Button } from 'react-admin';
import LayersIcon from '@mui/icons-material/Layers';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import MultipleUtilityUsageCreate from './MultipleUtilityUsageCreate'; // Adjust path as needed

const ReadingForFloorButton: React.FC = () => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button label="Floor Readings" onClick={handleClickOpen}>
                <LayersIcon style={{ marginRight: 4 }} /> {/* Adds some spacing between icon and text */}
                {/* Floor Readings */}
            </Button>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
                <DialogTitle>Floor Readings</DialogTitle>
                <DialogContent>
                    <MultipleUtilityUsageCreate /> {/* Pass props like onClose if needed */}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ReadingForFloorButton;
