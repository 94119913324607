import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { ArrayInput, SimpleFormIterator, ReferenceInput, SelectInput, TextInput, useDataProvider, useNotify, useRecordContext } from 'react-admin';
import { useWatch, useFormContext } from 'react-hook-form';
import { ContractServiceFee, ServiceFeeId } from '../../ts-types/types';
const inputStyles = { marginTop: '4px' };

/**
 * Merges defaultServiceFees and currentServiceFees based on service_fee_id.
 * If a service is in currentServiceFees but not in defaultServiceFees, it is still included.
 * @param defaultServiceFees - The array of default service fees.
 * @param currentServiceFees - The array of current service fees.
 * @returns The merged array of service fees.
 */
function mergeServiceFees(
    defaultServiceFees: ContractServiceFee[],
    currentServiceFees: ContractServiceFee[]
): ContractServiceFee[] {
    // Create a map from currentServiceFees for quick lookup by service_fee_id
    const currentServiceFeeMap = new Map(
        currentServiceFees.map(service => [service.service_fee_id, service])
    );

    // Merge default services and keep existing ones from currentServiceFees
    const mergedServiceFees = defaultServiceFees.map(defaultService => {
        const existingService = currentServiceFeeMap.get(defaultService.service_fee_id);
        return existingService ? Object.assign(defaultService, existingService) : defaultService;
    });

    // Add services that are in currentServiceFees but not in defaultServiceFees
    const missingServices = currentServiceFees.filter(
        service => !defaultServiceFees.some(defaultService => defaultService.service_fee_id === service.service_fee_id)
    );

    return [...mergedServiceFees, ...missingServices];
}

const ServiceFeeFormSection: React.FC = (props) => {
    const record = useRecordContext();
    // const [serviceFees, setServiceFees] = useState<any[]>([]);
    const [totalAmount, setTotalAmount] = useState<number>(0);  // Track the total amount
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const { setValue, watch, getValues } = useFormContext();  // Use watch to observe the form's state

    // Watch the room_id to detect changes
    const room_id = useWatch({ name: 'room_id' });
    const customers = useWatch({ name: 'contract_customers' }); //watch('contract_customers'); //
    // Watch the contract_service_fees to recalculate the total amount if negotiated fees are updated
    const contract_service_fees = watch('contract_service_fees');
    useEffect(() => {
        if (!customers)
            return;
        const values = getValues(); // Get all form values
        if (values.contract_service_fees && values.contract_service_fees.length > 0) {
            const serviceFees = values.contract_service_fees.filter(x => x.service_fee_id !== ServiceFeeId.BikeParkingLot && x.fee_calculation_method === 'MULTIPLY');
            let changed = false;
            for (const serviceFee of serviceFees) {
                if (serviceFee.negotiated_fee != serviceFee.default_fee * customers.length) {
                    changed = true;
                    serviceFee.negotiated_fee = serviceFee.default_fee * customers.length;
                }
            }
            const parkingFee = values.contract_service_fees.find(x => x.service_fee_id === ServiceFeeId.BikeParkingLot);
            const bikes = customers.filter(x => !!x.bike_license_plate).length; // count all, not free any one.
            if (parkingFee && parkingFee.negotiated_fee != parkingFee.default_fee * bikes) {
                changed = true;
                parkingFee.negotiated_fee = Math.max(0, parkingFee.default_fee * bikes);
            }
            if (changed) {
                setValue('contract_service_fees', values.contract_service_fees);
            }
        }
    }, [customers, contract_service_fees, getValues]);

    useEffect(() => {
        // Fetch service fees when the room_id changes
        const fetchServiceFees = async () => {
            try {
                if (room_id) {
                    // Step 1: Fetch room details to get room name
                    const { data: room } = await dataProvider.getOne('rooms', { id: room_id });
                    const roomName = room.room_name;

                    // Step 2: Fetch all "SERVICE" type service fees
                    const serviceFeesResult = await dataProvider.getList('service_fees', {
                        pagination: { page: 1, perPage: 100 },
                        sort: { field: 'id', order: 'ASC' },
                        filter: { fee_type: 'SERVICE' },
                    });

                    // Step 3: Fetch the rent fee for the selected room_name
                    const roomRentFeeResult = await dataProvider.getList('service_fees', {
                        pagination: { page: 1, perPage: 1 },
                        sort: { field: 'id', order: 'ASC' },
                        filter: { service_name: roomName, fee_type: 'RENT' },
                    });

                    // Step 4: Combine service fees and rent fee
                    const defaultServiceFees = [
                        ...(roomRentFeeResult.data.length > 0
                            ? [
                                {
                                    service_fee_id: roomRentFeeResult.data[0].id,
                                    negotiated_fee: roomRentFeeResult.data[0].default_fee,
                                    default_fee: roomRentFeeResult.data[0].default_fee,
                                    description: roomRentFeeResult.data[0].description,
                                    fee_calculation_method: roomRentFeeResult.data[0].fee_calculation_method,
                                    fee_type: roomRentFeeResult.data[0].fee_type,
                                    service_name: roomRentFeeResult.data[0].service_name
                                },
                            ]
                            : []),
                        ...serviceFeesResult.data.map((fee: any) => ({
                            service_fee_id: fee.id,
                            negotiated_fee: fee.default_fee,
                            default_fee: fee.default_fee,
                            description: fee.description,
                            fee_calculation_method: fee.fee_calculation_method,
                            fee_type: fee.fee_type,
                            service_name: fee.service_name
                        })),
                    ];

                    let serviceFees = defaultServiceFees;
                    if (!!record?.contract_service_fees) {
                        serviceFees = mergeServiceFees(defaultServiceFees, record.contract_service_fees);
                    }

                    // Step 5: Programmatically update the contract_service_fees in the form context
                    setValue('contract_service_fees', serviceFees);  // This ensures the form state is updated with new fees

                    // Step 6: Update total amount
                    const total = serviceFees.reduce((acc: number, fee: any) => acc + fee.negotiated_fee, 0);
                    setTotalAmount(total);  // Set the total amount
                }
            } catch (error) {
                notify('Error fetching service fees or room details', 'error');
            }
        };
        fetchServiceFees();
    }, [record, room_id, dataProvider, setValue, notify]);

    useEffect(() => {
        if (contract_service_fees) {
            const total = contract_service_fees.reduce((acc: number, fee: any) => acc + (fee.negotiated_fee || 0), 0);
            setTotalAmount(total);  // Recalculate total amount
        }
    }, [contract_service_fees]);

    // Function to format numbers with commas for better readability
    const formatNumber = (value: number) => {
        return new Intl.NumberFormat().format(value);
    };

    return (
        <Box style={{ width: '100%' }} >
            <ArrayInput source="contract_service_fees" label={false}>
                <SimpleFormIterator disableClear={true} disableAdd={true} disableReordering={true} disableRemove={true}>
                    <Grid container spacing={2} xs={12}>
                        <Grid item xs={8} sm={6} md={4}>
                            <ReferenceInput label="Service" reference="service_fees" source="service_fee_id">
                                <SelectInput optionText="service_name" sx={inputStyles} fullWidth />
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={4} sm={2} md={2}>
                            <TextInput
                                source="negotiated_fee"
                                label="Negotiated Fee"
                                sx={inputStyles}
                                parse={(v) => parseInt(v.replaceAll(',', ''), 10)}  // Parse the input value as an integer
                                format={(v) => (v ? formatNumber(v) : '')}  // Format the displayed number
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextInput source="description" label="Description" sx={inputStyles} fullWidth />
                        </Grid>
                    </Grid>
                </SimpleFormIterator>
            </ArrayInput>

            {/* Display the total amount below the service fees */}
            <Typography variant="h6" align="left">
                Total Amount: {formatNumber(totalAmount)} VND
            </Typography>
        </Box>
    );
};

export default ServiceFeeFormSection;
