import React, { useState, useEffect } from 'react';
import { Toolbar, SaveButton, Create, SimpleForm, useDataProvider, useNotify, useRedirect } from 'react-admin';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Typography } from '@mui/material';
import ContractFormSection from './ContractFormSection';
import ServiceFeeFormSection from './ServiceFeeFormSection';
import CustomerFormSection from './CustomerFormSection';
import { supabaseClient as supabase } from "../../core/supabase";
import PrintContractButton from './ContractFormPrint';
import { useParams } from 'react-router-dom';


const CustomToolbar = () => (
    <Toolbar>
        <SaveButton />
        <PrintContractButton />
    </Toolbar>
);

const ContractForm: React.FC = (props) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect();
    const { id } = useParams();  // If 'id' exists, it's edit mode; otherwise, it's create mode
    const [contractData, setContractData] = useState(null);

    // Fetch contract details in edit mode
    useEffect(() => {
        if (id) {
            const fetchContractDetails = async () => {
                const { data, error } = await supabase.rpc('get_contract_details', {
                    p_contract_id: id,
                });


                if (error) {
                    console.error('Error fetching contract details:', error);
                    notify('Error loading contract details', { type: 'error' });
                } else {
                    if (data.promotion_details) {
                        data.discount_amount = data.promotion_details.discount_amount;
                        data.promotion_end_date = data.promotion_details.promotion_end_date;
                        data.promotion_duration = Math.round((new Date(data.promotion_details.promotion_end_date) - new Date(data.start_date)) / (3600 * 24 * 30 * 1000.0))
                    }
                    setContractData(data);
                }
            };

            fetchContractDetails();
        }
    }, [id, notify]);


    const [openErrorDialog, setOpenErrorDialog] = useState(false); // Manage the dialog state
    const [errorMessage, setErrorMessage] = useState<string | null>(null); // Store the error message

    // Handle form submission
    const handleSave = async (values: any) => {
        const promotion_duration = values.promotion_duration;
        let promotionDetails = null;
        if (promotion_duration > 0) {
            promotionDetails = {
                promotion_start_date: values.start_date,
                promotion_end_date: values.promotion_end_date,
                discount_amount: values.discount_amount,
            };
        }

        try {
            // Call the custom 'saveContract' method from the data provider
            const result = await dataProvider.saveContract('contracts', {
                id,
                values,
                promotionDetails,
            });

            if (!id) { // new contract
                await dataProvider.generateInvoice('invoices', {
                    contractId: result.data.id,
                    cycle: 'contractCreatation',
                });
                await dataProvider.generateInvoice('invoices', {
                    contractId: result.data.id,
                    cycle: 'startDate',
                });
            }
            notify('Contract saved successfully', 'info');
            redirect('/contracts');
        } catch (error) {
            notify('Error saving contract: ' + error.message, 'error');
        }
    };

    const handleDialogClose = () => {
        setOpenErrorDialog(false);
        setErrorMessage(null);
    };

    return (
        <div>
            <Create {...props}>
                <SimpleForm toolbar={<CustomToolbar />} onSubmit={handleSave} record={contractData} defaultValues={{ status: 'ACTIVE', start_date: new Date(), contract_duration: '6 months' }}>
                    {/* Contract form section */}
                    <Typography variant="h5" align="right" sx={{ marginTop: '0px' }}>
                        Contract Information
                    </Typography>
                    <ContractFormSection />
                    <Typography variant="h5" align="right" sx={{ marginTop: '0px', marginBottom: '0px' }}>
                        Service Fees
                    </Typography>
                    {/* Service Fees section */}
                    <ServiceFeeFormSection />
                    <Typography variant="h5" align="right" sx={{ marginTop: '0px' }}>
                        Customer Information
                    </Typography>
                    {/* Customers section */}
                    <CustomerFormSection />
                </SimpleForm>
            </Create>

            {/* Error Dialog */}
            <Dialog open={openErrorDialog} onClose={handleDialogClose}>
                <DialogTitle>Error</DialogTitle>
                <DialogContent>
                    <DialogContentText>{errorMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ContractForm;
