import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    DateField,
    ReferenceField,
    SelectInput,
    FunctionField,
    Filter,
    DateInput
} from 'react-admin';
import ActiveContractInput from '../../components/ActiveContractInput';

import InvoiceViewButton from './InvoiceViewButton'; // Import the button
import dayjs from 'dayjs';
// Default filter values with time adjustments
const filterDefaultValues = {
    "invoice_date@gte": dayjs().startOf('month').format('YYYY-MM-DDT00:00:00'),
    "invoice_date@lte": dayjs().endOf('month').format('YYYY-MM-DDT23:59:59'),
    status: 'PENDING',
};
/**
 * Filter component for InvoiceList, including the contract and status filters.
 */
const InvoiceFilter: React.FC = (props) => (
    <Filter {...props}>
        <ActiveContractInput label="Room" source="contract_id" alwaysOn />
        {/* Status Filter for invoices */}
        <SelectInput
            label="Status"
            source="status"
            choices={[
                { id: 'PENDING', name: 'Pending' },
                { id: 'PAID', name: 'Paid' },
                { id: 'OVERDUE', name: 'Overdue' },
                { id: 'CANCELLED', name: 'Cancelled' },
                { id: 'REFUNDED', name: 'Refunded' },
                { id: 'DISPUTED', name: 'Disputed' },
            ]}
            alwaysOn
        />
        {/* Invoice date range filters */}
        <DateInput source="invoice_date@gte" label="Invoice Date From" alwaysOn />
        <DateInput source="invoice_date@lte" label="Invoice Date To" alwaysOn />
    </Filter>
);

/**
 * InvoiceList component displays a list of invoices filtered by contract ID and status.
 */
const InvoiceList: React.FC = () => {
    return (
        <List
            filters={<InvoiceFilter />}
            filterDefaultValues={filterDefaultValues} // Set default filter values
            sort={{ field: 'created_at', order: 'DESC' }}>
            <Datagrid rowClick="edit">
                {/* <TextField source="id" label="Invoice ID" /> */}
                <ReferenceField label="Room" source="contract_id" reference="contracts" link={false}>
                    <ReferenceField source="room_id" reference="rooms">
                        <TextField source="room_name" />
                    </ReferenceField>
                </ReferenceField>
                <DateField source="invoice_date" label="Invoice Date" />
                <DateField source="due_date" label="Due Date" />
                <NumberField source="total_amount" label="Total Amount" />
                <TextField source="status" label="Status" />
                {/* Add a conditional button for pending invoices */}
                <FunctionField
                    label="Actions"
                    render={(record: any) =>
                        record.status === 'PENDING' ? (
                            <InvoiceViewButton publicInvoiceId={record.unique_id} />
                        ) : null
                    }
                />
                {/* <DateField source="created_at" label="Created At" /> */}
            </Datagrid>
        </List>
    );
};

export default InvoiceList;
