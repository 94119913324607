import React from 'react';
import { Button, useDataProvider, useNotify } from 'react-admin';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
export const BikeListButton: React.FC = () => {
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const handleOpenTab = async () => {
        try {
            // Use the DataProvider to fetch data
            const { data } = await dataProvider.getList('customer_room_view', {
                pagination: { page: 1, perPage: 100 }, // Adjust as needed
                sort: { field: 'room_name', order: 'ASC' },
                filter: {
                    contract_status: 'ACTIVE',
                    // bike_license_plate_not_null: true, // Custom filter
                } // Filter for active contracts
            });

            // Generate content for new tab
            const content = `
                <html>
                <head><title>Bike List</title></head>
                <body>
                    <h1>Danh sách xe máy</h1>
                    <table border="1">
                        <thead>
                            <tr>
                                <th>Phòng</th>
                                <th>Khách</th>
                                <th>Biển số</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${data
                    .filter(x => !!x.bike_license_plate)
                    .map(
                        (row: any) => `
                                <tr>
                                    <td>${row.room_name || 'N/A'}</td>
                                    <td>${row.customer_name?.split(' ').pop() || 'N/A'}</td>
                                    <td>${row.bike_license_plate || 'N/A'}</td>
                                </tr>
                            `
                    )
                    .join('')}
                        </tbody>
                    </table>
                </body>
                </html>
            `;

            const newTab = window.open();
            if (newTab) {
                newTab.document.write(content);
                newTab.document.close();
            } else {
                notify('Unable to open new tab.', { type: 'error' });
            }
        } catch (err) {
            notify(`Error fetching bike list: ${err.message}`, { type: 'error' });
        }
    };

    return <Button label="Show Bike List" onClick={handleOpenTab} startIcon={<DirectionsBikeIcon />} />;
};
