import React, { useEffect, useState } from 'react';
import { AutocompleteInput, SelectInputProps, useRecordContext } from 'react-admin';
import { supabaseClient as supabase } from '../core/supabase';

/**
 * ActiveContractInput component that fetches active contracts and ensures compatibility with React-Admin's source prop.
 */
const ActiveContractInput: React.FC<SelectInputProps> = (props) => {
    const { source } = props; // Respect the source prop
    const [contractChoices, setContractChoices] = useState<any[]>([]);
    const record = useRecordContext(); // Get the current record from context

    useEffect(() => {
        const fetchContracts = async () => {
            try {
                // Fetch active contracts
                const { data, error } = await supabase
                    .from('contracts')
                    .select('id, rooms(room_name)')
                    .eq('status', 'ACTIVE');

                if (error) {
                    console.error('Error fetching active contracts:', error);
                    return;
                }

                // Map active contracts to choices
                let choices = data.map((contract: any) => ({
                    id: contract.id,
                    name: contract.rooms.room_name,
                }));

                // Ensure current value is included
                if (record && record[source]) {
                    const currentChoice = choices.find((choice) => choice.id === record[source]);
                    if (!currentChoice) {
                        const currentRoomName = await supabase
                            .from('contracts')
                            .select('rooms(room_name)')
                            .eq('id', record[source])
                            .single();

                        if (currentRoomName?.data) {
                            choices = [
                                ...choices,
                                {
                                    id: record[source],
                                    name: currentRoomName.data.rooms.room_name,
                                },
                            ];
                        }
                    }
                }

                setContractChoices(choices);
            } catch (err) {
                console.error('Unexpected error fetching contracts:', err);
            }
        };

        fetchContracts();
    }, [record, source]);

    return <AutocompleteInput {...props} choices={contractChoices} />;
};

export default ActiveContractInput;
