
import AssetList from './AssetList';
import AssetCreate from './AssetCreate';

const AssetPage = {
    name: 'assets',
    list: AssetList,
    create: AssetCreate,
    // edit: ContractEdit,
    // show: ContractShow,
};

export default AssetPage;
