import React, { useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import { ReferenceInput, SelectInput, DateInput, TextInput, required, useRecordContext } from 'react-admin';
import { useWatch, useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import ImageFileInput from '../../components/ImageFileInput';  // Import the custom ImageFileInput component
import electricMeterPlaceholder from '../../decorations/electric-meter-electricity.svg';    // Back ID placeholder
import CurrencyInput from '../../components/CurrencyInput';
import { supabaseClient as supabase } from "../../core/supabase";
// Helper function to extract query parameters
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};
const inputStyles = { marginTop: '8px' };
// Function to format numbers with commas for better readability

const ContractFormSection: React.FC = () => {
    const record = useRecordContext();
    const { setValue } = useFormContext();  // Use setValue to programmatically update form values

    const query = useQuery();  // Get query parameters from the URL
    const roomIdFromQuery = query.get('room_id');  // Extract the room_id from the query
    const start_date = useWatch({ name: 'start_date' });
    const contract_duration = useWatch({ name: 'contract_duration' });
    const promotionDuration = useWatch({ name: 'promotion_duration' }); // Watch promotion_duration
    const roomId = useWatch({ name: 'room_id' }); // Watching room_id for changes
    const contractServiceFees = useWatch({ name: 'contract_service_fees' }); // Watching room_id for changes
    // Update promotion_end_date based on the selected promotion duration and start date
    useEffect(() => {
        if (start_date && promotionDuration > 0) {
            const promotionEndDate = new Date(start_date);
            promotionEndDate.setMonth(promotionEndDate.getMonth() + promotionDuration);
            setValue('promotion_end_date', promotionEndDate);
        } else {
            setValue('promotion_end_date', null); // Clear promotion end date if no promotion
        }
    }, [promotionDuration, start_date, setValue]);

    useEffect(() => {
        if (roomIdFromQuery && !record) {
            // Set the room_id in the form context if it exists in the URL
            setTimeout(() => setValue('room_id', parseInt(roomIdFromQuery, 10)), 1000)
        }
    }, [roomIdFromQuery, setValue]);

    useEffect(() => {
        // Update end date when contract_duration or start_date changes
        if (start_date && contract_duration) {
            let newEndDate, commission, depositAmount;
            let rentFee = contractServiceFees?.find(x => x.fee_type === 'RENT')?.negotiated_fee || 0;
            if (contract_duration === '6 months') {
                newEndDate = dayjs(start_date).add(6, 'month').toDate();
                commission = Math.ceil(0.5 * rentFee / 10) * 10;
                depositAmount = rentFee * 1;
            } else if (contract_duration === '1 year') {
                newEndDate = dayjs(start_date).add(12, 'month').toDate();
                commission = Math.ceil(0.7 * rentFee / 10) * 10;
                depositAmount = rentFee * 1.5;
            } else {
                newEndDate = null;  // Let the user manually set the end date for 'other'
            }
            if (newEndDate) {
                setValue('end_date', newEndDate);
            }
            if (commission) {
                setValue('commission_fee', commission);
            }
            if (depositAmount) {
                setValue('deposit_amount', depositAmount);
            }
        }
    }, [contractServiceFees, start_date, contract_duration, setValue]);

    useEffect(() => {
        if (roomId) {
            // Set the room_id in the form context if it exists in the URL
            const fetchRoomDetails = async () => {
                const { data } = await supabase.rpc('get_house_and_room_info', {
                    p_room_id: roomId,
                });
                setValue('room_details', data);
            };

            fetchRoomDetails();
        }
    }, [roomId, setValue]);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>

                {/* Left: Proof Upload Area */}
                <Grid item xs={12} md={4} lg={4}>
                    <Grid item xs={12} sm={12} md={12} >
                        <Box display="flex" justifyContent="center">
                            <TextInput
                                source="electric_meter_reading"
                                label="Electric Meter Reading"
                                validate={required()}
                                sx={inputStyles}
                                helperText={false}
                            // fullWidth
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                        <Box display="flex" justifyContent="center">
                            <ImageFileInput
                                style={{
                                    backgroundImage: `url('${electricMeterPlaceholder}')`,
                                }}
                                source="image_url"
                                label=""
                                prefix="meters"
                            />
                        </Box>
                    </Grid>

                </Grid>

                {/* Right: Form Fields in Multiple Lines */}
                <Grid item xs={12} md={8} lg={8}>
                    <Grid container spacing={2}>
                        {/* Room Number Input - Room selection needs more space */}
                        <Grid item xs={6} sm={4} md={3} xl={2}>
                            <ReferenceInput
                                label="Room"
                                reference="rooms"
                                source="room_id"
                                filter={{ "status@in": `(AVAILABLE, TERMINATING)` }}
                            >
                                <SelectInput optionText="room_name" sx={inputStyles} validate={required()} defaultValue={roomIdFromQuery} readOnly={!!record} />
                            </ReferenceInput>
                        </Grid>

                        {/* Contract Duration - Smaller field */}
                        <Grid item xs={6} sm={4} md={3} xl={2}>
                            <SelectInput
                                source="contract_duration"
                                label="Contract Duration"
                                defaultValue="6 months"
                                choices={[
                                    { id: '6 months', name: '6 months' },
                                    { id: '1 year', name: '1 year' },
                                    { id: 'other', name: 'Other' }
                                ]}
                                sx={inputStyles}
                                validate={required()}
                            />
                        </Grid>

                        {/* Start Date - Smaller field */}
                        <Grid item xs={6} sm={4} md={3} xl={2}>
                            <DateInput
                                source="start_date"
                                label="Start Date"
                                defaultValue={new Date()}
                                sx={inputStyles}
                                validate={required()}
                            />
                        </Grid>

                        {/* End Date - Smaller field */}
                        <Grid item xs={6} sm={4} md={3} xl={2}>
                            <DateInput
                                source="end_date"
                                label="End Date"
                                sx={inputStyles}
                                validate={required()}
                            />
                        </Grid>

                        <Grid item xs={6} sm={4} md={3} xl={2}>
                            <SelectInput
                                source="payment_day"
                                label="Payment Day"
                                choices={Array.from({ length: 30 }, (_, i) => ({ id: i + 1, name: i + 1 }))}
                                validate={required()}
                                defaultValue={1}
                                sx={inputStyles}
                                readOnly={true}
                            />
                        </Grid>

                        {/* Deposit Amount - CurrencyInput */}
                        <Grid item xs={6} sm={4} md={3} xl={2}>
                            <CurrencyInput
                                source="deposit_amount"
                                label="Deposit Amount"
                                validate={required()}
                                sx={inputStyles}
                            />
                        </Grid>

                        {/* Commission Fee - CurrencyInput */}
                        <Grid item xs={6} sm={4} md={3} xl={2}>
                            <CurrencyInput
                                source="commission_fee"
                                label="Commission Fee"
                                validate={required()}
                                sx={inputStyles}
                            />
                        </Grid>

                        {/* Promotion Duration - Small field */}
                        <Grid item xs={6} sm={4} md={3} xl={2}>
                            <SelectInput
                                source="promotion_duration"
                                label="Promotion Duration (Months)"
                                choices={[
                                    { id: 0, name: 'No Promotion' },
                                    { id: 1, name: '1 Month' },
                                    { id: 2, name: '2 Months' },
                                    { id: 3, name: '3 Months' },
                                    { id: 6, name: '6 Months' },
                                    { id: 12, name: '12 Months' },
                                ]}
                                defaultValue={0}
                            />
                        </Grid>

                        {/* Discount Amount - Only appears when promotion is selected */}
                        {promotionDuration > 0 && (
                            <Grid item xs={6} sm={4} md={3} xl={2}>
                                <CurrencyInput
                                    source="discount_amount"
                                    label="Discount Amount"
                                    sx={inputStyles}
                                />
                            </Grid>
                        )}

                        {/* Hidden Water Meter Reading */}
                        <Grid item xs={0} md={0} style={{ visibility: 'hidden' }}>
                            <TextInput
                                source="water_meter_reading"
                                label="Water Meter Reading"
                                sx={inputStyles}
                                defaultValue={0}
                            />
                        </Grid>
                    </Grid>

                </Grid>

            </Grid>
        </Box>
    )
};

export default ContractFormSection;
