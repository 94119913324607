import React, { useContext } from 'react';
import { Button, useRedirect, RecordContext } from 'react-admin'; // Use Button from react-admin
import AddIcon from '@mui/icons-material/Add'; // Import the plus icon
import AssignmentIcon from '@mui/icons-material/Assignment';

const CustomActions = () => {
    const record = useContext(RecordContext);  // Get the record from the context
    const redirect = useRedirect();

    const handleNewContract = (event: React.MouseEvent, roomId: number) => {
        event.stopPropagation();  // Prevent the event from bubbling up
        redirect(`/contracts/create?room_id=${roomId}`);
    };

    const handleViewInvoices = (event: React.MouseEvent, roomId: number) => {
        event.stopPropagation();  // Prevent the event from bubbling up
        redirect(`/invoices?filter=%7B"room_id"%3A${roomId}%2C"status"%3A"unpaid"%7D`);
    };

    const handleAssignClick = (event: React.MouseEvent, roomId: number) => {
        event.stopPropagation();  // Prevent the event from bubbling up
        redirect(`/rooms/${roomId}/assets`);
    };

    if (!record) return null;

    return (
        <div>
            <Button label="Assign assets" onClick={(event) => handleAssignClick(event, record.id)}>
                <AssignmentIcon />
            </Button>
            {record.status === 'AVAILABLE' && (
                <Button
                    label="Rent Room" // Shortened text
                    onClick={(event) => handleNewContract(event, record.id)}
                >
                    <AddIcon /> {/* Plus icon */}
                </Button>
            )}

            {(record.status === 'INVOICE AVAILABLE' || record.status === 'OVERDUE') && (
                <Button
                    label="View Unpaid Invoices"
                    onClick={(event) => handleViewInvoices(event, record.id)}
                />
            )}

        </div>
    );
};

export default CustomActions;
