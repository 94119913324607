import React from 'react';
import { TopToolbar, useRecordContext } from 'react-admin';
import VoidButton from './VoidButton';
import PaidButton from './PaidButton';
import PrintButton from './PrintButton';
import AddInvoiceItemButton from './AddInvoiceItemButton';
import InvoiceViewButton from './InvoiceViewButton'; // Reuse the button component

/**
 * Custom action buttons for the InvoiceShow action bar.
 * Only displays Void and Paid buttons if the invoice status is "PENDING".
 */
const InvoiceShowActions: React.FC = () => {
    const record = useRecordContext();

    if (!record) return null;

    return (
        <TopToolbar>
            {record.status === 'PENDING' && (
                <>
                    <AddInvoiceItemButton refresh={() => window.location.reload()} />
                    <VoidButton invoiceId={record.id} refresh={() => window.location.reload()} />
                    <PaidButton invoiceId={record.id} refresh={() => window.location.reload()} />
                    <InvoiceViewButton publicInvoiceId={record.unique_id} />
                </>
            )}
            <PrintButton invoiceId={record.id} record={record} />
        </TopToolbar>
    );
};

export default InvoiceShowActions;
