import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Layout,
    SimpleForm,
    Create,
    useNotify,
    AutocompleteInput,
    Button,
    Toolbar,
    SaveButton,
    Loading,
} from 'react-admin';
import AssetListIterator from './AssetListIterator';
import AssetPrintButton from './AssetPrintButton';
import { supabaseClient as supabase } from '../../core/supabase';
import PrintIcon from '@mui/icons-material/Print';

type Room = {
    id: number;
    room_name: string;
};
const RoomAssets: React.FC = () => {
    const { roomId } = useParams<{ roomId: string }>();
    const [rooms, setRooms] = useState<Room[]>([]);
    const [selectedRoom, setSelectedRoom] = useState<number | null>(parseInt(roomId, 10) || null);
    const notify = useNotify();
    const navigate = useNavigate();
    // Fetch rooms for dropdown
    useEffect(() => {
        const fetchRooms = async () => {
            try {
                const { data, error } = await supabase
                    .from('rooms')
                    .select('id, room_name');

                if (error) throw error;
                setRooms(data || []);
            } catch (error) {
                notify('Error loading rooms', { type: 'error' });
            }
        };
        fetchRooms();
    }, [notify]);
    // Handle room selection change
    const handleRoomChange = (newRoomId) => {
        setSelectedRoom(newRoomId);
        navigate(`/rooms/${newRoomId}/assets`);
    };

    // Save assets to the room
    const handleSave = async (values) => {
        const assets = values.assets;
        try {
            // Clear existing assets for the room
            await supabase
                .from('assets')
                .update({ room_id: null })
                .eq('room_id', roomId);
            // Extract all asset IDs to update
            const assetIds = assets.map((asset) => asset.id);
            // Bulk update the room_id for selected assets
            await supabase
                .from('assets')
                .update({ room_id: roomId })
                .in('id', assetIds);

            notify('Assets updated successfully', { type: 'success' });
            navigate('/assets?displayedFilters=[]&filter=' + encodeURIComponent(`{"room_id":${roomId}}`));

        } catch (error) {
            console.error(error);
            notify('Error saving assets', { type: 'error' });
        }
    };
    return (
        <Create resource='assets'>
            <SimpleForm onSubmit={handleSave} defaultValues={{ assets: [] }} toolbar={
                <Toolbar>
                    <SaveButton />
                    <AssetPrintButton />
                </Toolbar>
            }>
                <AutocompleteInput
                    source="room"
                    label="Select Room"
                    choices={rooms.map(room => ({ id: room.id, name: room.room_name }))}
                    optionValue='id'
                    onChange={handleRoomChange}
                    defaultValue={selectedRoom}
                />
                {selectedRoom && <AssetListIterator roomId={String(selectedRoom)} />}
            </SimpleForm>
        </Create>
    );
};

export default RoomAssets;
