
import React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton, ListProps, Filter, SelectInput, TextInput } from 'react-admin';
const ServiceFeeFilter: React.FC = (props) => (
    <Filter {...props}>
        <SelectInput
            label="Fee Type"
            source="fee_type"
            alwaysOn={true}
            choices={[
                { id: 'RENT', name: 'Rent' },
                { id: 'SERVICE', name: 'Service' },
            ]}
        />
        <TextInput
            label="Service Name"
            source="service_name"
            alwaysOn={true} />
    </Filter>
);
const ServiceFeeList: React.FC<ListProps> = (props) => (
    <List {...props} filters={<ServiceFeeFilter />}>
        <Datagrid>
            {/* <TextField source="id" /> */}
            <TextField source="service_name" label="Service Name" />
            <TextField source="fee_type" label="Fee Type" />
            <TextField source="default_fee" label="Default Fee" />
            <TextField source="description" label="Description" />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export default ServiceFeeList;
