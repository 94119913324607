import React, { useState } from 'react';
import { Show, TextField, NumberField, DateField, Button, ReferenceField, useDataProvider, TopToolbar, useRecordContext } from 'react-admin';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from '@mui/material';
import PaidIcon from '@mui/icons-material/Done';
import PrintIcon from '@mui/icons-material/Print';
import VoidIcon from '@mui/icons-material/DeleteOutline';
import ConfirmButton from '@mui/icons-material/Check';
import CancelButton from '@mui/icons-material/Cancel';
import InvoiceItems from './InvoiceItems'; // Import InvoiceItems
import InvoicePaymentProof from './InvoicePaymentProof'; // Import InvoiceItems
import InvoiceShowActions from './InvoiceShowActions';
/**
 * InvoiceShow component displays an invoice as a commercial invoice, including all items.
 * The action bar is used for Void, Paid, and Print buttons.
 */
const InvoiceShow: React.FC = (props) => {
    return (
        <Show actions={<InvoiceShowActions />} {...props}>
            <Grid container spacing={2}>
                {/* Display Room Name using ReferenceField */}
                <Grid item xs={12} md={6} lg={3}>
                    <Typography variant="subtitle1" gutterBottom>
                        Room
                    </Typography>
                    <ReferenceField source="contract_id" reference="contracts" link={false}>
                        <ReferenceField source="room_id" reference="rooms">
                            <TextField source="room_name" />
                        </ReferenceField>
                    </ReferenceField>
                </Grid>

                {/* Invoice Date */}
                <Grid item xs={12} md={6} lg={3}>
                    <Typography variant="subtitle1" gutterBottom>
                        Invoice Date
                    </Typography>
                    <DateField source="invoice_date" />
                </Grid>


                {/* Status */}
                <Grid item xs={12} md={6} lg={3}>
                    <Typography variant="subtitle1" gutterBottom>
                        Status
                    </Typography>
                    <TextField source="status" />
                </Grid>
            </Grid>
            {/* Display Invoice Items */}
            <InvoiceItems />
            <InvoicePaymentProof />
        </Show>
    );
};

export default InvoiceShow;
