import React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton, ListProps, ReferenceField, Filter, SelectInput, TextInput } from 'react-admin';
import CustomActions from './CustomActions'; // Import the CustomActions component
const RoomFilter: React.FC = (props) => (
    <Filter {...props}>
        <SelectInput
            label="Status"
            source="status"
            alwaysOn={true}
            choices={[
                { id: 'AVAILABLE', name: 'Available' },
                { id: 'TERMINATING', name: 'Terminating' },
                { id: 'OCCUPIED', name: 'Occupied' },
                { id: 'OUT OF SERVICE', name: 'Out of Service' },
                { id: 'INVOICE AVAILABLE', name: 'Invoice Available' },
                { id: 'OVERDUE', name: 'Overdue' },
            ]}
        />
        <TextInput
            label="Room Name"
            source="room_name"
            alwaysOn={true} />
    </Filter>
);
const RoomList: React.FC<ListProps> = (props) => {
    return (
        <List {...props} filters={<RoomFilter />}>
            <Datagrid >
                {/* <ReferenceField source="house_id" reference="houses" label="House Name">
                    <TextField source="house_name" />
                </ReferenceField> */}
                <TextField source="room_name" label="Room Name" />
                <TextField source="description" label="Description" />
                <TextField source="area" label="Area" />
                <TextField source="duplex_area" label="Duplex Area" />
                <TextField source="status" label="Status" />
                <CustomActions /> {/* Use the CustomActions component */}
                <EditButton />
                <DeleteButton />
            </Datagrid>
        </List>
    );
};

export default RoomList;
