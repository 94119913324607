
import React from 'react';
import { Edit, SimpleForm, TextInput, NumberInput, EditProps, SelectInput } from 'react-admin';

const ServiceFeeEdit: React.FC<EditProps> = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="service_name" label="Service Name" />
            <SelectInput
                source="fee_type"
                label="Fee Type"
                choices={[
                    { id: 'RENT', name: 'Rent' },
                    { id: 'SERVICE', name: 'Service' },
                ]}
                defaultValue="SERVICE"
            />
            <NumberInput source="default_fee" label="Default Fee" />
            <TextInput source="description" label="Description" />
        </SimpleForm>
    </Edit>
);

export default ServiceFeeEdit;
