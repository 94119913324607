import React from 'react';
import { Show, SimpleShowLayout, ReferenceField, TextField, DateField, NumberField, ShowProps } from 'react-admin';
import ImageDisplay from '../../components/ImageDisplay';

/**
 * Component to display details of a utility usage record.
 *
 * This component shows utility usage details such as room name (nested reference to contracts and rooms),
 * month/year, electric meter reading, and an image URL.
 *
 * @param props - The props for the Show view, inherited from react-admin's ShowProps.
 */
const UtilityUsageShow: React.FC<ShowProps> = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            {/* Room Name: Nested ReferenceField to display room name associated with the contract */}

            <ReferenceField label="Room" source="contract_id" reference="contracts" link={false}>
                <ReferenceField source="room_id" reference="rooms" link={false}>
                    <TextField source="room_name" />
                </ReferenceField>
            </ReferenceField>

            {/* Month/Year: Displayed as a date field */}
            <DateField source="month_year" />

            {/* Electric Meter Reading: Displayed as a number */}
            <NumberField source="electric_meter_reading" />

            {/* Image URL: Displayed using a custom ImageDisplay component */}
            <ImageDisplay source="image_url" />
        </SimpleShowLayout>
    </Show>
);

export default UtilityUsageShow;
