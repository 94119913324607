import React from 'react';
import { List, Datagrid, TextField, NumberField, useRecordContext, FunctionField } from 'react-admin';
import { useTheme, Box, Typography } from '@mui/material';

/**
 * InvoiceItems component to display items of an invoice using List and Datagrid.
 * Zebra stripes, row numbers are added, total amount is displayed at the bottom, pagination and action bar are removed.
 */
const InvoiceItems: React.FC = () => {
    const record = useRecordContext();
    const theme = useTheme();  // Access the theme (light or dark)
    const postRowSx = (record, index) => {
        record.index = index + 1;
    };
    if (!record) return null;

    return (
        <Box>
            <List
                title={false}
                resource="invoice_items"
                basePath="/invoice_items"
                filter={{ invoice_id: record.id }}  // Filter items by current invoice_id
                hasCreate={false}
                hasEdit={false}
                hasShow={false}
                hasList={false}
                exporter={false}
                actions={false}  // Hide action bar
                pagination={false}  // Disable pagination
            >
                <Datagrid
                    rowClick={false}
                    bulkActionButtons={false}
                    sx={{
                        '& .RaDatagrid-rowOdd': {
                            backgroundColor: theme.palette.mode === 'light'
                                ? theme.palette.grey[200]  // Light theme background for odd rows
                                : theme.palette.grey[800],  // Dark theme background for odd rows
                        },
                    }}
                    rowSx={postRowSx}
                >
                    {/* Correct row number */}
                    <TextField source="index" label="No." />
                    <TextField source="description" label="Item Description" />
                    <NumberField source="amount" label="Amount" />
                </Datagrid>
            </List>

            {/* Total amount row */}
            <Box display="flex" justifyContent="flex-end" sx={{ padding: '1rem', backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900] }}>
                <Typography variant="h6" fontWeight="bold">
                    Total Amount: {record.total_amount.toLocaleString('en-US', { maximumFractionDigits: 0 }) /* The total amount will need to be calculated manually */}
                </Typography>
            </Box>
        </Box>
    );
};

export default InvoiceItems;
