import React, { useState, useEffect } from 'react';
import { TextField, Create, SimpleForm, TextInput, NumberInput, ReferenceInput, SelectInput, CreateProps, useDataProvider, useNotify, useRedirect } from 'react-admin';
import { useForm } from 'react-hook-form';

const RoomCreate: React.FC<CreateProps> = (props) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="room_name" label="Room Name" />
                <ReferenceInput source="house_id" reference="houses" label="House">
                    <SelectInput optionText="house_name" defaultValue={1} />
                </ReferenceInput>
                <NumberInput source="area" label="Area (m²)" />
                <NumberInput source="floor" label="Floor" />
                <NumberInput source="capacity" label="Capacity" />
                <NumberInput source="allowed_parking_lots" label="Allowed Parking Lots" />
                <NumberInput source="available_parking_lots" label="Available Parking Lots" />
                <NumberInput source="duplex_area" label="Duplex Area (m²)" />
                <SelectInput
                    source="status"
                    choices={[
                        { id: 'OUT OF SERVICE', name: 'Out of Service' },
                        { id: 'AVAILABLE', name: 'Available' },
                        { id: 'TERMINATING', name: 'Terminating' },
                        { id: 'OCCUPIED', name: 'Occupied' },
                        { id: 'INVOICE AVAILABLE', name: 'Invoice Available' },
                        { id: 'OVERDUE', name: 'Overdue' },
                    ]}
                    defaultValue={'AVAILABLE'}
                />
                <TextInput source="room_image" label="Room Image URL" />
                <TextInput source="description" label="Description" multiline />
            </SimpleForm>
        </Create>

    );
};

export default RoomCreate;
