
import RoomList from './RoomList';
import RoomCreate from './RoomCreate';
import RoomEdit from './RoomEdit';
import RoomShow from './RoomShow';

const RoomPage = {
    name: 'rooms',
    list: RoomList,
    edit: RoomEdit,
    create: RoomCreate,
    show: RoomShow
};

export default RoomPage;
