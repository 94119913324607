import React, { useState } from 'react';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    DateField,
    ReferenceField,
    Filter,
    Button,
    TopToolbar,
    CreateButton, ExportButton
} from 'react-admin';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import ActiveContractInput from '../../components/ActiveContractInput'; // Reuse the contract filter input
import ReadingForFloorButton from './ReadingForFloorButton';



// Toolbar component with the custom button
const ListActions: React.FC = () => (
    <TopToolbar>
        <CreateButton />
        <ReadingForFloorButton />
        <ExportButton />
        {/* Add other buttons if needed */}
    </TopToolbar>
);
/**
 * Filter component to select a contract in the UtilityUsageList.
 * The options are fetched from Supabase to get active contracts and room names.
 */
const UtilityUsageFilter: React.FC = (props) => {
    return (
        <Filter {...props}>
            <ActiveContractInput
                label="Room"
                source="contract_id"
                alwaysOn // Make the filter always visible
            />
        </Filter>
    );
};

/**
 * UtilityUsageList component displays a list of utility usages filtered by contract ID.
 */
const UtilityUsageList: React.FC = () => {
    return (
        <List filters={<UtilityUsageFilter />} sort={{ field: 'created_at', order: 'DESC' }} actions={<ListActions />}>
            <Datagrid>
                <TextField source="id" />
                <ReferenceField label="Room" source="contract_id" reference="contracts" link={false}>
                    <ReferenceField source="room_id" reference="rooms">
                        <TextField source="room_name" />
                    </ReferenceField>
                </ReferenceField>
                <DateField source="month_year" label="Month/Year" />
                <NumberField source="electric_meter_reading" label="Electric Meter Reading" />
                {/* <NumberField source="water_meter_reading" label="Water Meter Reading" /> */}
                {/* <ImageField source="image_url" label="Proof Image" /> */}
                <DateField source="created_at" label="Created At" />
                {/* <DateField source="updated_at" label="Updated At" /> */}
            </Datagrid>
        </List>
    );
};

export default UtilityUsageList;
