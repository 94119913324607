import * as React from 'react';
import { List, Datagrid, TextField, DateField, NumberField, ReferenceField, AutocompleteInput, ReferenceInput, Button, TopToolbar, ExportButton, CreateButton, useRedirect, ListProps } from 'react-admin';
import QRTextInput from '../../components/QRTextInput'
import { useMediaQuery } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
const postFilters = [
    <QRTextInput label="Serial Number" source="serial_number" alwaysOn defaultZoom={1} />,
    <ReferenceInput source="room_id" reference="rooms" label="Room" alwaysOn perPage={1000} >
        <AutocompleteInput optionText="room_name" optionValue="id" filterToQuery={searchText => ({ room_name: searchText })} />
    </ReferenceInput >
];
const AssetList = (props) => {
    // Media queries for different screen sizes
    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
    const isMedium = useMediaQuery((theme: any) => theme.breakpoints.between('sm', 'md'));

    return (
        <List filters={postFilters} {...props}>
            <Datagrid rowClick="edit">
                <TextField source="serial_number" label="Serial Number" />
                <TextField source="asset_type" label="Type" />
                <DateField source="purchase_date" label="Purchase Date" />
                <ReferenceField source="room_id" reference="rooms" label="Room">
                    <TextField source="room_name" />
                </ReferenceField>

                {/* Conditionally render supplier_id based on screen size */}
                {!isSmall &&
                    <ReferenceField source="supplier_id" reference="suppliers" label="Supplier">
                        <TextField source="supplier_name" />
                    </ReferenceField>
                }
                {!isSmall && <DateField source="warranty_expiry" label="Warranty Expiry" />}
                {!isSmall && <TextField source="status" label="Status" />}
                {!isSmall && <NumberField source="price" label="Price" />}
                {!isSmall && <DateField source="created_at" label="Created At" />}
                {!isSmall && <DateField source="updated_at" label="Updated At" />}
            </Datagrid>
        </List>
    )
};

export default AssetList;
