import React from 'react';
import React, { useState, useEffect } from 'react';
import { List, Datagrid, TextField, NumberField, useRecordContext, FunctionField } from 'react-admin';
import { useTheme, Box, Typography } from '@mui/material';
import ImageDisplay from '../../components/ImageDisplay';  // Import the custom ImageFileInput component
import { supabaseClient as supabase } from '../../core/supabase'; // Importing the Supabase client
/**
 * InvoiceItems component to display items of an invoice using List and Datagrid.
 * Zebra stripes, row numbers are added, total amount is displayed at the bottom, pagination and action bar are removed.
 */
const InvoicePaymentProof: React.FC = () => {
    const record = useRecordContext();
    const theme = useTheme();  // Access the theme (light or dark)
    const postRowSx = (record, index) => {
        record.index = index + 1;
    };
    if (!record || record.status != "PAID") return null;
    const [payment, setPayment] = useState<string | null>(null);
    useEffect(() => {
        if (!record.paid_proof_url) {
            // Set the room_id in the form context if it exists in the URL
            const fetchPayment = async () => {
                const { data, error } = await supabase.from<Contract>('invoice_payments')
                    .select('*')
                    .eq('invoice_id', record.id)
                    .single();
                if (error) {
                    throw new Error(`Unable to get contracts. Error: ${JSON.stringify(error)}`);
                }
                setPayment(data);
            };


            fetchPayment();
        }
    }, [record]);

    return (
        <Box>
            {record.paid_proof_url && <ImageDisplay
                source="paid_proof_url"
                label=""
                prefix="meters"
            />}
            {payment && (
                <div>
                    <h3>Payment Details</h3>
                    <div>
                        <strong>Payment Method:</strong> {payment.payment_method}
                    </div>
                    <div>
                        <strong>Payment Date:</strong> {new Date(payment.created_at).toLocaleString()}
                    </div>
                    <div>
                        <strong>Amount:</strong> {new Intl.NumberFormat().format(payment.amount)}
                    </div>
                    <div>
                        <strong>External Description:</strong> {payment.external_description}
                    </div>
                </div>
            )}

        </Box>
    );
};

export default InvoicePaymentProof;
