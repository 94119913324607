import React, { useState, useEffect } from 'react';
import { useRecordContext, useNotify, Button, SaveButton, useRefresh } from 'react-admin';
import { supabaseClient as supabase } from '../../core/supabase';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { SelectInput, TextInput, SimpleForm } from 'react-admin';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const AddInvoiceItemButton: React.FC = () => {
    const record = useRecordContext(); // Access the current invoice record
    const notify = useNotify();
    const refresh = useRefresh(); // Hook to trigger a refresh
    const [open, setOpen] = useState(false);
    const [serviceFees, setServiceFees] = useState([]);
    const [selectedFee, setSelectedFee] = useState<any>(null);

    useEffect(() => {
        // Fetch service fees with IDs 50 and 51 from Supabase
        const fetchServiceFees = async () => {
            const { data, error } = await supabase
                .from('service_fees')
                .select('id, service_name, default_fee')
                .in('id', [50, 51]); // Filter to show only certain IDs

            if (error) {
                notify('Error fetching service fees', 'warning');
            } else {
                setServiceFees(data);
            }
        };

        fetchServiceFees();
    }, [notify]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = async (values: any) => {
        if (!record) return;

        try {
            // Call the PostgreSQL function to add the item and update the total amount
            const { error } = await supabase.rpc('add_invoice_item_and_update_total', {
                p_invoice_id: record.id,
                p_fee_id: values.fee_id,
                p_amount: -values.amount,
                p_description: selectedFee?.service_name, // Use selected service name as description
            });

            if (error) {
                throw new Error(error.message);
            }

            notify('Invoice item added and total amount updated successfully', 'info');
            handleClose();
            refresh(); // Refresh the current page to reflect the updated invoice
        } catch (error) {
            notify('Error adding invoice item or updating total amount', 'warning');
        }
    };

    return (
        <Box>
            <Button onClick={handleClickOpen} label="Add Invoice Item">
                <AddCircleIcon />
            </Button>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add New Invoice Item</DialogTitle>
                <DialogContent>
                    <SimpleForm
                        onSubmit={handleSave}
                        toolbar={
                            <DialogActions>
                                <SaveButton label="Save" />
                                <Button onClick={handleClose} label="Cancel" />
                            </DialogActions>
                        }
                    >
                        {/* Dropdown for Service Fee */}
                        <SelectInput
                            source="fee_id"
                            label="Service Fee"
                            choices={serviceFees.map((fee) => ({
                                id: fee.id,
                                name: fee.service_name,
                            }))}
                            onChange={(event) => {
                                const feeId = event.target.value;
                                const fee = serviceFees.find((fee) => fee.id === feeId);
                                setSelectedFee(fee);
                            }}
                        />

                        {/* Amount Input */}
                        <TextInput source="amount" label="Amount" defaultValue={selectedFee?.default_fee} />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default AddInvoiceItemButton;
