import * as React from 'react';
import { Create, SimpleForm, useNotify, useRedirect, useDataProvider } from 'react-admin';
// import { supabase } from '../supabaseClient'; // Ensure you import the Supabase client from the correct path
import AssetFormFields from './AssetFormFields';  // Import the new form fields component

const AssetCreate: React.FC = (props) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();

    const handleSave = async (values) => {

        const { serial_numbers, supplier_id, category_name, purchase_date, warranty_expiry, warranty_term, price, ...rest } = values;

        try {
            // Loop over each serial number and create a new asset for each
            await Promise.all(                
                serial_numbers.map(({ serial_number }) => {
                    const payload = {
                        serial_number: serial_number.trim(), // Ensure the serial number is trimmed
                        asset_type: category_name,
                        supplier_id,
                        purchase_date,
                        warranty_expiry,
                        price,
                        ...rest, // Any additional fields not explicitly listed
                    };

                    // Call dataProvider to create the asset
                    return dataProvider.create('assets', { data: payload });
                })
            );

            // Notify the user of success
            notify('Assets saved successfully', { type: 'success' });

            // Redirect after save
            redirect('list', 'assets');
        } catch (error) {
            console.log(error);
            // Notify the user of an error
            notify(`Error saving assets: ${error.message}`, { type: 'warning' });
        }
    };


    return (
        <Create {...props}>
            <SimpleForm onSubmit={handleSave}>
                <AssetFormFields />  {/* Reuse form fields inside SimpleForm */}
            </SimpleForm>
        </Create>
    );
};

export default AssetCreate;
