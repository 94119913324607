import React, { useState, useEffect } from 'react';
import { useDataProvider, useNotify, useRedirect, required, FormDataConsumer, Create, SimpleForm, SelectInput, TextInput, NumberInput, ArrayInput, SimpleFormIterator, CreateProps } from 'react-admin';
import { Box, Typography, Grid } from '@mui/material';
import { supabaseClient as supabase } from '../../core/supabase';
import ImageFileInput from '../../components/ImageFileInput';
import { useFormContext as useReactHookFormContext } from 'react-hook-form';

interface UtilityUsageRecord {
    room_name: string;
    electric_meter_reading: number;
    previous_reading: number;
    contract_id: bigint | null;
}

/**
 * Subcomponent for form fields, including floor selection, proof image, and room entries.
 */
const MultipleUtilityUsageCreateForm: React.FC = () => {
    const { setValue } = useReactHookFormContext(); // Access form context for dynamic field updates
    const [floor, setFloor] = useState<number>(2);
    const notify = useNotify();

    useEffect(() => {
        const fetchRoomsAndContracts = async (floor) => {
            try {
                // Call the PostgreSQL function fetchRoomsAndContracts with the specified floor as input
                const { data, error } = await supabase.rpc('fetch_rooms_and_contracts', { floor_input: floor });

                if (error) {
                    console.error('Error fetching rooms and contracts:', error);
                    notify('Failed to fetch rooms and contract data', { type: 'error' });
                    return;
                }

                // Map the fetched data to the expected format for the form
                const formattedRooms = data.map(room => ({
                    room_name: room.room_name,
                    electric_meter_reading: 0,
                    previous_reading: room.previous_reading || 0,
                    contract_id: room.contract_id,
                }));

                // Update the rooms array in the form context
                setValue('rooms', formattedRooms);
            } catch (err) {
                console.error('Unexpected error:', err.message);
                notify('Unexpected error fetching room and contract data', { type: 'error' });
            }
        };

        fetchRoomsAndContracts(floor);
    }, [floor, notify, setValue]);

    return (
        <>
            {/* Floor selection dropdown */}
            <SelectInput
                source="floor"
                choices={[{ id: 2, name: '2' }, { id: 3, name: '3' }, { id: 4, name: '4' }, { id: 5, name: '5' }]}
                onChange={(e) => setFloor(parseInt(e.target.value))}
            />

            {/* Proof Image Upload */}
            <Box mb={2}>
                <Typography variant="h6">Upload Proof Image</Typography>
                <ImageFileInput source="proof_image" style={{ width: '1000px' }} validate={required()} />
            </Box>

            {/* Room Entries with SimpleFormIterator */}
            <Typography variant="h6">Enter Electric Meter Readings</Typography>
            <ArrayInput source="rooms">
                <SimpleFormIterator disableRemove>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={3}>
                            <TextInput
                                source="room_name"
                                label="Room"
                                readOnly
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextInput
                                source="previous_reading"
                                label="Previous Reading"
                                readOnly
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormDataConsumer>
                                {({ scopedFormData, ...rest }) => (
                                    <NumberInput
                                        source="electric_meter_reading"
                                        label="Electric Meter Reading"
                                        validate={[
                                            value => (value > 0 ? undefined : 'Must be greater than 0'),
                                            value =>
                                                value > scopedFormData.previous_reading ? undefined : 'Must be greater than previous reading'
                                        ]}
                                        {...rest}
                                    />
                                )}
                            </FormDataConsumer>
                        </Grid>

                        <Grid item xs={3}>
                            <TextInput
                                source="contract_id"
                                label="Contract ID"
                                style={{ visibility: 'hidden' }} // Hide the contract ID field
                            />
                        </Grid>
                    </Grid>
                </SimpleFormIterator>
            </ArrayInput>
        </>
    );
};

/**
 * Main component for creating multiple UtilityUsage records.
 *
 * This component wraps the form fields within a SimpleForm, allowing
 * the creation of multiple utility usage entries for rooms on a selected floor.
 *
 * @param props - The props for the Create view, inherited from react-admin's CreateProps.
 */
const MultipleUtilityUsageCreate: React.FC<CreateProps> = (props) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();

    const handleSave = async (data) => {
        const validEntries = data.rooms
            .filter(record => record.electric_meter_reading > record.previous_reading && record.electric_meter_reading > 0)
            .map(record => ({
                contract_id: record.contract_id,
                month_year: new Date(),
                electric_meter_reading: record.electric_meter_reading,
                image_url: data.proof_image,
                water_meter_reading: 0,
            }));

        if (validEntries.length === 0) {
            notify("No valid entries to submit.", { type: 'warning' });
            return;
        }

        try {
            await Promise.all(validEntries.map(entry => dataProvider.create('utility_usages', { data: entry })));
            notify("Utility usage records created successfully.", { type: 'info' });
            redirect('/utility_usages');
        } catch (error) {
            notify("Error creating utility usage records.", { type: 'error' });
        }
    };

    return (
        <Create {...props}>
            <SimpleForm onSubmit={handleSave}>
                <MultipleUtilityUsageCreateForm /> {/* Reuse form fields inside SimpleForm */}
            </SimpleForm>
        </Create>
    );
};

export default MultipleUtilityUsageCreate;
